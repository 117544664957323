<template >
  <div class="vx-row">
    <div class="vx-col w-full mt-5">
      <label class>Do you have siblings at school?</label>
      <ul class="vx-row mt-2 w-full">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="sibling_data.have"
            vs-value="siblings_have_true"
            name="siblings_have"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="sibling_data.have"
            vs-value="siblings_have_false"
            name="siblings_have"
          >No</vs-radio>
        </li>
      </ul>
    </div>
    <!-- If yes -->
    <div v-if="sibling_data.have.includes('true')">
      <div
        class="repeater mb-3"
        v-for="(sibling, index) in sibling_data.siblings"
        :key="sibling.id"
      >
        <div class="repeat_elm vx-row">
          <!-- school sibling ? -->

          <!-- if at school -->
          <div class="vx-col w-full mt-5" v-if="sibling.at_school.includes('true')">
            <!--  Id ? -->

            <!-- If Have ID -->
            <div v-if="sibling.school_data.have_id.includes('true')" class="w-full mt-5">
              <vs-input
                type="text"
                v-model="sibling_data.siblings[index].school_data.id_num"
                class="vx-col w-full"
                name="id_num"
                v-validate="'required'"
                placeholder="Drop the ID here"
              />
              <span class="text-danger text-sm">{{ errors.first('step-5.id_num') }}</span>
            </div>
          </div>
        </div>
        <!-- Actions -->
        <div class="btn-group text-right w-full">
          <vs-button
            type="line"
            icon-pack="feather"
            ize="small"
            @click="pushSiblings(sibling_data.siblings,sibling)"
          >Add Another Siblings</vs-button>
          <vs-button
            type="line"
            icon-pack="feather"
            ize="small"
            color="danger"
            @click="shiftElm(sibling_data.siblings,sibling.id)"
            v-if="sibling_data.siblings.length>1"
          >Remove</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Datepicker from 'vuejs-datepicker'
import commonData from './common_data.js'

export default {
  name: 'register-siblings-data',
  inject: ['$validator'],
  props: {
    sibling_data: {
      type: Object
    }
  },
  data () {
    return {
      //   Disable Dates  for Birthdate
      disabledDates: {
        from: new Date()
      }
    }
  },
  methods: {
    // Add Elemets to Array Value
    pushElmsValue (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          value: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Add Elemets to Array Value
    pushNums (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.num === elm_target.num && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          key: '',
          num: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },

    // Remove Elemets to Array Values
    shiftElm (elm_array, target_id) {
      for (let i = 0; i < elm_array.length; i++) {
        const element = elm_array[i]
        if (element.id === target_id) {
          elm_array.splice(i, 1)
        }
      }
    },

    // Autocomplete National List
    nationalitiesSuggestionList () {
      return this.nationalities_all
    },
    // Push Siblings
    pushSiblings (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.name === elm_target.name && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,

          at_school: 'at_school_true',
          //   School data
          school_data: {
            have_id: 'have_id_true',
            id_num: ''
          }
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    }
  },
  components: {
    Datepicker,
    VueSimpleSuggest
  },
  created () {
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/vuexy/extraComponents/autocomplete";
.vx-input-group-default {
  .vue-simple-suggest.designed {
    .suggest-item span {
      text-transform: capitalize;
    }
    .input-wrapper input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      text-transform: capitalize;
    }
  }
}
</style>

