<template>
  <div class="vx-row">
    <!-- First name -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        First Name
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyLetters"
        @keydown.space="allowOnlyOneSpace"
        v-model="student.name.first"
        class="w-full"
        name="first_name"
        placeholder="In English"
        v-validate="'required'"
      />
      <span class="text-danger text-sm">{{ errors.first('step-1.first_name') }}</span>
      <span
        v-if="!errors.first('step-1.first_name')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.first_name }}</span>
    </div>
    <!-- Middle name -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        Middle Name (father's)
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyLetters"
        @keydown.space="allowOnlyOneSpace"
        v-model="student.name.middle"
        name="middle_name"
        class="w-full"
        placeholder="In English"
        v-validate="'required'"
      />
      <span class="text-danger text-sm">{{ errors.first('step-1.middle_name') }}</span>
      <span
        v-if="!errors.first('step-1.middle_name')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.middle_name }}</span>
    </div>
    <!-- Last name -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        Last Name (Family)
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyLetters"
        @keydown.space="allowOnlyOneSpace"
        v-model="student.name.last"
        name="last_name"
        class="w-full"
        placeholder="In English"
        v-validate="'required'"
      />
      <span class="text-danger text-sm">{{ errors.first('step-1.last_name') }}</span>
      <span
        v-if="!errors.first('step-1.last_name')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.last_name }}</span>
    </div>
    <!-- Birth date -->
    <div class="vx-col md:w-1/2 w-full mt-5">
      <label>
        Birth date
        <span class="text-danger">*</span>
      </label>
      <datepicker
        placeholder="Date"
        v-model="student.birth_date"
        v-validate="'required'"
        name="birth_date"
        :disabled-dates="disabledDates"
      ></datepicker>
      <span class="text-danger text-sm">{{ errors.first('step-1.birth_date') }}</span>
    </div>
    <!-- Gender -->
    <div class="vx-col lg:w-1/2 md:w-1/2 w-full mt-5">
      <label class>Gender</label>
      <ul class="vx-row mt-2">
        <li class="vx-col w-1/2">
          <vs-radio v-model="student.gender" vs-value="female" name="gender">Female</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio v-model="student.gender" vs-value="male" name="gender">Male</vs-radio>
        </li>
      </ul>
    </div>
    <!-- Religion -->
    <div class="vx-col xl:w-full lg:w-1/2 md:w-1/2 w-full mt-5">
      <label class>
        Religion
        <span class="text-danger">*</span>
      </label>
      <vs-select
        v-model="student.religion"
        name="religion"
        class="w-full select-large"
        v-validate="'required'"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item,index) in religions"
          class="w-full"
        />
      </vs-select>
      <span
        class="text-danger text-sm"
        v-show="errors.has('step-1.religion')"
      >{{ errors.first('step-1.religion') }}</span>
    </div>
    <!-- Nationality -->
    <div class="vx-col w-full mt-5">
      <label>
        Nationality
        <span class="text-danger">*</span>
      </label>
      <div class="vx-col w-full mt-5">
        <v-select
          multiple
          :closeOnSelect="false"
          v-model="student.nationalities"
          v-validate="'required'"
          class="w-full"
          name="nationality"
          :options="nationalities_all"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <br />
      </div>
      <span
        class="text-danger text-sm"
        v-show="errors.has('step-1.nationality')"
      >{{ errors.first('step-1.nationality') }}</span>
    </div>
    <!-- Address -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>
        Address
        <span class="text-danger">*</span>
      </label>
      <div class="vx-row">
        <!-- Country -->
        <vs-select
          v-model="student.address.country"
          @change="student.address.city = ''; student.address.district = '';"
          name="address_country"
          class="select-small vx-col md:w-1/3 mb-3 w-full"
          placeholder="Country"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in countries"
            class="w-full"
          />
        </vs-select>

        <!-- city -->
        <vs-select
          v-if="student.address.country"
          v-model="student.address.city"
          @change="student.address.district = ''"
          name="address_city"
          class="select-small vx-col md:w-1/3 mb-3 w-full"
          placeholder="city"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item,index) in cities"
            class="w-full"
          />
        </vs-select>

        <!-- district -->
        <vs-select
          v-if="student.address.city && student.address.country"
          v-model="student.address.district"
          name="address_district"
          class="select-small vx-col md:w-1/3 mb-3 w-full"
          placeholder="district"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in districts"
            class="w-full"
          />
        </vs-select>

        <!-- street -->
        <vs-input
          @keypress="acceptOnlyLetters"
          @keydown.space="allowOnlyOneSpace"
          v-model="student.address.street"
          placeholder="Street"
          name="address_street"
          class="vx-col mb-3 w-full"
          v-validate="'required'"
        />
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-1.address_street')"
        >
          {{
          errors.first('step-1.address_street') }}
        </span>
        <span
          v-if="!errors.first('step-1.address_street')"
          class="text-danger text-sm vx-col"
        >{{ extraValidationErrors.address_street }}</span>
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-1.address_country')"
        >
          {{
          errors.first('step-1.address_country') }}
        </span>
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-1.address_city')"
        >
          {{
          errors.first('step-1.address_city') }}
        </span>
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-1.address_district')"
        >
          {{
          errors.first('step-1.address_district') }}
        </span>
      </div>
    </div>
    <!--  mobile -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>
        Mobile
        <span class="text-danger">*</span>
      </label>
      <!-- Key -->
      <div class="vx-row">
        <vs-select
          v-model="student.mobile.key"
          name="mobile_key"
          class="select-small vx-col w-1/4 mb-3 pr-0"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in mobile_keys"
            class="w-full"
          />
        </vs-select>

        <!-- number -->
        <!-- @change="student.mobile.num=(student.mobile.num).length > 8 ?  `${student.mobile.num.substring(3)}` : `${student.mobile.num}`" -->
        <vs-input
          @keypress="acceptOnlyNumbers"
          placeholder="mobile"
          v-model="student.mobile.num"
          class="vx-col w-3/4 mb-3 pl-1"
          name="mobile_num"
          v-validate="'required|numeric|length:8'"
        />
      </div>
      <span class="text-danger text-sm">{{ errors.first('step-1.mobile_num') }}</span>
      <span
        v-if="!errors.first('step-1.mobile_num')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.mobile_num }}</span>
    </div>
    <!--   Tel -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>
        Telephone
        <span class="text-danger">*</span>
      </label>
      <div class="required|numeric" v-for="(tel,index) in student.tel" :key="tel.id=index+1">
        <div class="vx-row repeater_elm mb-3">
          <!-- Key -->
          <vs-select
            v-model="tel.key"
            name="tel_key"
            class="select-small w-1/4 repend-text border-0 vx-col pr-0"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in tel_keys"
              class="w-full"
            />
          </vs-select>

          <vx-input-group
            class="vx-col w-3/4 pl-1"
            prependClasses="border border-solid border-grey border-r-0"
          >
            <!-- number -->
            <!-- @change="tel.num=(tel.num).length > 8 ?  `${tel.num.substring(((tel.num).length )-8)}` : `${tel.num}`" -->
            <vs-input
              @keypress="acceptOnlyNumbers"
              placeholder="Landline"
              v-model="tel.num"
              class="w-full"
              name="tel_num"
              v-validate="'required|numeric|length:8'"
            />
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="pushNums(student.tel,tel)"
                ></vs-button>
                <vs-button
                  type="border"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-minus"
                  @click="shiftElm(student.tel,tel.id)"
                  v-if="student.tel.length > 1"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <span class="text-danger text-sm">{{ errors.first('step-1.tel_num') }}</span>
      <span
        v-if="!errors.first('step-1.tel_num')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.tel_num }}</span>
    </div>
    <!--  Email -->
    <div class="vx-col md:w-1/2 w-full mt-5">
      <label>
        Student Email
        <span class="text-danger">*</span>
      </label>
      <vs-input
        type="email"
        v-validate="'required|email'"
        v-model="student.email"
        class="w-full"
        name="student_email"
      />
      <span class="text-danger text-sm">{{ errors.first('step-1.student_email') }}</span>
    </div>
    <!-- Official  Email -->
    <div class="vx-col md:w-1/2 w-full mt-5">
      <label>
        Official mailing Email
        <span class="text-danger">*</span>
      </label>
      <div class="repeater">
        <vs-input
          type="email"
          v-model="student.official_email"
          class="w-full"
          name="official_email"
          v-validate="'required|email'"
        />
      </div>
      <span class="text-danger text-sm">{{ errors.first('step-1.official_email') }}</span>
    </div>
    <!-- Student Id  -->
    <div class="vx-col w-full mt-5">
      <label>
        Student ID
        <span class="text-danger">*</span>
      </label>
      <vs-input
        type="text"
        @keypress="acceptOnlyNumbers"
        v-model="student.id_num"
        class="w-full"
        name="id_num"
        v-validate="'required|numeric|length:14'"
        description-text="Located at the student birth certificate"
      />
      <span class="text-danger text-sm">{{ errors.first('step-1.id_num') }}</span>
      <span
        v-if="!errors.first('step-1.id_num')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.id_num }}</span>
    </div>
    <!-- Applying for  -->
    <div class="vx-col w-full mt-5">
      <label>
        Applying for
        <span class="text-danger">*</span>
      </label>
      <!-- School -->
      <div class="mb-3">
        <vs-select
          @change="changeSchool"
          placeholder="Choose School"
          v-model="student.school"
          name="school"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.short_name"
            v-for="(item,index) in schools"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger text-sm">{{ errors.first('step-1.school') }}</span>
      </div>
      <!-- Division -->
      <div class="mb-3">
        <vs-select
          placeholder="Division"
          @change="changeDivision"
          :disabled="is_divionDisabled == 1"
          v-model="student.division"
          name="division"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.long_name"
            v-for="(item,index) in divisions"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger text-sm">{{ errors.first('step-1.division') }}</span>
      </div>
      <div class="mb-3">
        <!-- Grade -->
        <vs-select
          :disabled="disabled == true"
          placeholder="Year Group / Grade"
          v-model="student.grade"
          name="grade"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item,index) in grades "
            class="w-full"
          />
        </vs-select>
        <span class="text-danger text-sm">{{ errors.first('step-1.grade') }}</span>
      </div>
      <!-- School Year -->
      <div class="mb-3">
        <vs-select
          placeholder="School Year (Academic Year)"
          v-model="student.school_year"
          name="school_year"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in school_years"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger text-sm">{{ errors.first('step-1.school_year') }}</span>
      </div>
      <!-- School Month Start -->
      <div class="mb-3">
        <vs-select
          placeholder="Month to Start School (October)"
          v-model="student.school_month_start"
          name="school_month_start"
          class="select-small w-full"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in school_month_start_options"
            class="w-full"
          />
        </vs-select>
      </div>
    </div>

    <!-- recptcha -->
    <!-- <vue-recaptcha sitekey="6LfeRuwUAAAAAOPA7oSF1HakFlV7sP_w6FwNM60W"></vue-recaptcha> -->
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Datepicker from 'vuejs-datepicker'
import commonData from './common_data.js'
import { Validator } from 'vee-validate'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const RoundsRepository = RepositoryFactory.get('AdmissionModule', 'rounds')
import { OnlineAdmissionService } from './_services'
//   import VueRecaptcha from 'vue-recaptcha';
export default {
  name: 'register-student-data',
  inject: ['$validator'],
  props: {
    student: {
      type: Object
    }
  },
  data () {
    return {
      disabled:true,
      is_divionDisabled:true,
      extraValidationErrors: {
        first_name: null,
        middle_name: null,
        last_name: null,
        address_street: null,
        mobile_num: null,
        tel_num: null,
        id_num: null
      },
      // Common Array
      religions: commonData.religions,
      countries: commonData.countries,
      mobile_keys: commonData.mobile_keys,
      nationalities_all: commonData.nationalities,
      tel_keys: commonData.tel_keys,
      divisions: commonData.divisions,
      grades: commonData.grades,
      schools: commonData.schools,
      school_years: commonData.school_years,
      school_month_start_options: commonData.school_month_start_options,
      //   Disable Dates  for Birthdate
      disabledDates: {
        customPredictor (date) {
          const current_year = 2015,
            date_year = date.getFullYear()
          if (date_year > current_year) {
            return true
          }
        }
      }
    }
  },
  methods: {
    getGrades (id) {
      let Grads = []
      this.disabled = false
      RoundsRepository.getGrades(id).then((res) => {
        this.grades = []
        this.grades = res.data
        Grads = res.data
      })
      return Grads
    },
    changeDivision () {
      this.disabled = false
      this.$emit('update', 'grade')
      this.getGrades(this.student.division)

    },

    changeSchool () {
      const schoolCount = this.student.school
      if (schoolCount.length == 0) {
        this.grades = []
        this.divisions = []
        this.is_divionDisabled = true
        this.disabled = true
        this.$emit('update', 'division and grade')
      } else {
        this.grades = []
        this.divisions = []
        this.is_divionDisabled = false
        this.disabled = true
        this.$emit('update', 'division and grade')
        this.getSchoolDivisions(this.student.school)

      }

    },
    getSchoolDivisions (schoolIdes) {
      RoundsRepository.getSchoolDivisions(schoolIdes).then((res) => {
        this.divisions = []
        this.divisions = res.data
      })
    },
    nationalities () {
      OnlineAdmissionService.nationalities().then(res => {
        this.nationalities_all = res.data
      })
    },
    activeRound () {
      OnlineAdmissionService.activeRound().then(res => {
        this.schools = res.data.schoolsData
        this.disabled = true
        this.divionDisabled = true
        this.grades = res.data.gradesData
      })
    },
    acceptOnlyLetters (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Upper Case Letters
      if (charCode >= 65 && charCode <= 90) return true

      // Lower Case Letters
      if (charCode >= 97 && charCode <= 122) return true

      // Strees Address Allow Numbers
      if (field_name === 'address_street' && charCode >= 48 && charCode <= 57) return true

      // Space Charachter
      if (charCode === 32) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Letters and Spaces are the only allowed characters'
      newChar.preventDefault()
    },
    acceptOnlyNumbers (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Numbers Characters
      if (charCode >= 48 && charCode <= 57) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Only Numbers Are Allowed in this field'
      newChar.preventDefault()
    },
    allowOnlyOneSpace (space) {
      const field_name = space.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const value = space.originalTarget.value
      if (value.length !== 0 && value[value.length - 1] !== ' ') return true
      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Extra Space Error",
      //   text: "Only one space is allowed",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] = 'Only one space is allowed'
      space.preventDefault()
    },
    // Add Elemets to Array Value
    pushElmsValue (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          value: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Add Elemets to Array Value
    pushNums (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.num === elm_target.num && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          key: '',
          num: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },

    // Remove Elemets to Array Values
    shiftElm (elm_array, target_id) {
      for (let i = 0; i < elm_array.length; i++) {
        const element = elm_array[i]
        if (element.id === target_id) {
          elm_array.splice(i, 1)
        }
      }
    },

    // Autocomplete National List
    nationalitiesSuggestionList () {
      return this.nationalities_all
    },

    // checkExist
    checkExist (elm_array, elm_target) {
      // var exists = this.fields.some(function(field) {
      //   return field.value === this.field.value
      // });

      const exists = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (exists) {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        return false
      }
    }

    // Recaptcha
    // ,CaptchaCallback () {
    //   const recaptcha_key = '6LfeRuwUAAAAAOPA7oSF1HakFlV7sP_w6FwNM60W'

    //   if (document.getElementById('recaptcha-studentDataForm').length) {
    //     grecaptcha.render('recaptcha-studentDataForm', {'sitekey' : recaptcha_key, 'callback' : this.studentDataFormRecaptcha})
    //   }
    // },
    // studentDataFormRecaptcha (response) {
    //   document.getElementById('ct-recaptcha-studentDataForm').val(response)
    // }
  },
  components: {
    Datepicker,
    VueSimpleSuggest,
    Validator,
    'v-select': vSelect
    // VueRecaptcha
  },
  computed: {
    cities () {
      const selectedCountry = this.countries.find(country => {
        return country.id === this.student.address.country
      })
      return selectedCountry.cities
    },
    districts () {
      const selectedCity = this.cities.find(city => {
        return city.id === this.student.address.city
      })
      if (!selectedCity) return ''
      return selectedCity.districts
    }
  },
  created () {
    this.student.address.country = ''
    this.activeRound()
    this.nationalities()
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/vuexy/extraComponents/autocomplete";
.vx-input-group-default {
  .vue-simple-suggest.designed {
    .suggest-item span {
      text-transform: capitalize;
    }
    .input-wrapper input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      text-transform: capitalize;
    }
  }
}
</style>

