<template >
  <div class="vx-row">
    <!-- When Emergency we will Contact :  -->
    <div class="vx-row mt-5 w-full">
      <label>
        <b>When Emergency we will Contact</b>
      </label>

      <div
        class="repeater mb-3"
        v-for="(emergency, index) in general_data.emergencies"
        :key="(emergency.id = index + 1)"
      >
        <div class="repeat_elm vx-row">
          <!-- Full name -->
          <div class="vx-col w-full mt-5">
            <label>Full name</label>
            <div class="vx-row">
              <vs-select
                v-model="emergency.full_name.sign"
                name="name_sign"
                class="select-small w-1/4 repend-text border-0 vx-col pr-0"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in name_signs"
                  class="w-full"
                />
              </vs-select>
              <vs-input
                v-model="emergency.full_name.name"
                class="w-3/4 vx-col pl-1"
                name="full_name"
                placeholder="Full Name In English"
                v-validate="'required'"
              />
              <span class="text-danger vx-col w-full">{{
                errors.first("step-7.full_name")
              }}</span>
            </div>
          </div>
          <!-- Relationship -->
          <div class="vx-col w-full mt-5">
            <label class>Relationship</label>
            <vs-select
              v-model="emergency.relationship"
              name="relationship"
              class="select-small w-full vx-col mb-3 pr-0"
              placeholder="Relationship"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in relationships"
                class="w-full"
              />
            </vs-select>
            <span class="text-danger">{{
              errors.first("step-6.relationship")
            }}</span>
          </div>
          <!--  mobile -->
          <div class="centerx w-full mt-5 vx-col">
            <label class>Mobile</label>

            <div
              class="repeater"
              v-for="(mobile, index) in emergency.mobile"
              :key="(mobile.id = index + 1)"
            >
              <div class="vx-row repeater_elm mb-3">
                <!-- Key -->
                <vs-select
                  v-model="mobile.key"
                  name="mobile_key"
                  class="select-small w-1/4 repend-text border-0 vx-col pr-0"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in mobile_keys"
                    class="w-full"
                  />
                </vs-select>

                <vx-input-group
                  class="vx-col w-3/4 pl-1"
                  prependClasses="border border-solid border-grey border-r-0"
                >
                  <!-- number -->
                  <vs-input
                    placeholder="Mobile"
                    v-model="mobile.num"
                    class="w-full"
                    name="mobile_num"
                    @change="
                      mobile.num =
                        mobile.num.length > 8
                          ? `${mobile.num.substring(mobile.num.length - 8)}`
                          : `${mobile.num}`
                    "
                  />
                  <!-- Add Another -->
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="pushElmsValue(emergency.mobile, mobile)"
                      ></vs-button>
                      <vs-button
                        type="border"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-minus"
                        @click="shiftElm(emergency.mobile, mobile.id)"
                        v-if="emergency.mobile.length > 1"
                      ></vs-button>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("step-7.mobile_num") }}
            </span>
          </div>
          <!--   Tel -->
          <div class="centerx w-full mt-5 vx-col">
            <label class>Telephone</label>
            <div
              class="repeater"
              v-for="(tel, index) in emergency.tel"
              :key="(tel.id = index + 1)"
            >
              <div class="vx-row repeater_elm mb-3">
                <!-- Key -->
                <vs-select
                  v-model="tel.key"
                  name="tel_key"
                  class="select-small w-1/4 repend-text border-0 vx-col pr-0"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in tel_keys"
                    class="w-full"
                  />
                </vs-select>

                <vx-input-group
                  class="vx-col w-3/4 pl-1"
                  prependClasses="border border-solid border-grey border-r-0"
                >
                  <!-- number -->
                  <vs-input
                    placeholder="Landline"
                    v-model="tel.num"
                    class="w-full"
                    name="tel_num"
                    @change="
                      tel.num =
                        tel.num.length > 8
                          ? `${tel.num.substring(tel.num.length - 8)}`
                          : `${tel.num}`
                    "
                  />
                  <!-- Add Another -->
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="pushNums(emergency.tel, tel)"
                      ></vs-button>
                      <vs-button
                        type="border"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-minus"
                        @click="shiftElm(emergency.tel, tel.id)"
                        v-if="emergency.tel.length > 1"
                      ></vs-button>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </div>
            <span class="text-danger text-sm">{{
              errors.first("step-7.tel_num")
            }}</span>
          </div>

          <!-- Address -->
          <div class="centerx w-full mt-5 vx-col">
            <label class="md:w-1/6 w-full vx-col pr-0">Address</label>
            <div class="vx-row mt-4">
              <!-- Country -->
              <vs-select
                v-model="emergency.address.country"
                @change="
                  emergency.address.city = '';
                  emergency.address.district = '';
                "
                name="address_country"
                class="select-small vx-col md:w-1/3 mb-3 w-full"
                placeholder="Country"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id"
                  :text="item.name"
                  v-for="(item, index) in countries"
                  class="w-full"
                />
              </vs-select>

              <!-- city -->
              <vs-select
                v-if="emergency.address.country"
                v-model="emergency.address.city"
                @change="emergency.address.district = ''"
                name="address_city"
                class="select-small vx-col md:w-1/3 mb-3 w-full"
                placeholder="city"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id"
                  :text="item.name"
                  v-for="(item, index) in getCities(index)"
                  class="w-full"
                />
              </vs-select>

              <!-- district -->
              <vs-select
                v-if="emergency.address.city && emergency.address.country"
                v-model="emergency.address.district"
                name="address_district"
                class="select-small vx-col md:w-1/3 mb-3 w-full"
                placeholder="district"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id"
                  :text="item.name"
                  v-for="(item, index) in getDistricts(
                    index,
                    emergency.address.city
                  )"
                  class="w-full"
                />
              </vs-select>

              <!-- street -->
              <vs-input
                placeholder="Street"
                v-model="emergency.address.street"
                name="address_street"
                class="vx-col mb-3 w-full"
                v-validate="'required'"
              />
              <span class="text-danger ml-2 vx-col">{{
                errors.first("step-7.address_street")
              }}</span>
              <span class="text-danger ml-2 vx-col">{{
                errors.first("step-7.address_country")
              }}</span>
              <span class="text-danger ml-2 vx-col">{{
                errors.first("step-7.address_city")
              }}</span>
              <span class="text-danger ml-2 vx-col">{{
                errors.first("step-7.address_district")
              }}</span>
            </div>
          </div>

          <!-- Actions -->
          <div class="btn-group text-right w-full">
            <vs-button
              type="line"
              icon-pack="feather"
              ize="small"
              @click="pushEmergencies"
              >Add More</vs-button
            >
            <vs-button
              type="line"
              icon-pack="feather"
              ize="small"
              color="danger"
              @click="shiftElm(general_data.emergencies, emergency.id)"
              v-if="general_data.emergencies.length > 1"
              >Remove</vs-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Transpaortation -->
    <div class="vx-col w-full mt-5">
      <ul class="vx-row mt-2">
        <li class="vx-col xl:w-1/2 w-full">
          <label>Well you require bus transportation ?</label>
        </li>
        <li class="vx-col w-1/4">
          <vs-radio
            v-model="general_data.transpaortation"
            vs-value="transpaortation_true"
            name="transpaortation"
            >Yes</vs-radio
          >
        </li>
        <li class="vx-col w-1/4">
          <vs-radio
            v-model="general_data.transpaortation"
            vs-value="transpaortation_false"
            name="transpaortation"
            >No</vs-radio
          >
        </li>
      </ul>
    </div>

    <!-- Address On Map  -->
    <div class="vx-col w-full mt-5">
      <ul class="vx-row mt-2">
        <li class="vx-col w-full xl:w-1/4">
          <label>Address On Map</label>
        </li>
        <li class="vx-col xl:w-3/4 w-full">
          <vx-input-group
            prependClasses="border border-solid border-grey border-r-0"
          >
            <vs-input
              v-model="general_data.map.address"
              name="map_address"
              placeholder="google map"
              v-validate="'required'"
              readonly
              @click.prevent="googleAddress"
              @focus="map_popupActive = true"
            />
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  type="border"
                  icon-pack="feather"
                  icon="icon-map"
                  :disabled="true"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </li>
      </ul>
      <!-- popup -->
      <vs-popup
        class="holamundo"
        :active.sync="map_popupActive"
        title="Google Map"
      >
        <gmap-map
          :center="gMapCenter"
          :zoom="15"
          style="width: 100%; height: 500px"
        >
          <gmap-marker
            :key="i"
            v-for="(m, i) in markers"
            :position="(gMapCenter = m.position)"
            v-model="m.position"
            :clickable="true"
            @click="getPostion(m, i)"
          ></gmap-marker>
        </gmap-map>
        <vs-button
          @click="savePosition"
          color="primary"
          class="mt-4 pull-right mr-0 ml-auto"
          type="filled"
          style="display: block"
          >Save Address</vs-button
        >
      </vs-popup>
      <!-- validation error  -->
      <span class="text-danger vx-col w-full">{{
        errors.first("step-7.map_address")
      }}</span>
    </div>

    <!-- Permanent or recurring health problem -->
    <div class="vx-col w-full mt-5">
      <ul class="vx-row mt-2">
        <li class="vx-col xl:w-1/2 w-full">
          <label>Permanent or recurring health problem</label>
        </li>
        <li class="vx-col xl:w-1/4 w-1/2">
          <vs-radio
            v-model="general_data.health.problem"
            vs-value="problem_true"
            name="health_problem"
            >Yes</vs-radio
          >
        </li>
        <li class="vx-col xl:w-1/4 w-1/2">
          <vs-radio
            v-model="general_data.health.problem"
            vs-value="problem_false"
            name="health_problem"
            >No</vs-radio
          >
        </li>
      </ul>
      <div class="mt-2" v-if="general_data.health.problem.includes('true')">
        <vs-textarea
          placeholder="More Details "
          v-model="general_data.health.details"
          name="details"
          class="mb-3 w-full vx-col"
        ></vs-textarea>
      </div>
    </div>

    <!-- Submit bedore -->
    <div class="vx-col w-full mt-5">
      <ul class="vx-row mt-2">
        <li class="vx-col w-full">
          <label
            >Have you previously submitted an application with us before
            ?</label
          >
        </li>
        <li class="vx-col xl:w-1/4 w-1/2">
          <vs-radio
            v-model="general_data.submit_before"
            vs-value="submit_before_true"
            name="submit_before"
            >Yes</vs-radio
          >
        </li>
        <li class="vx-col xl:w-1/4 w-1/2">
          <vs-radio
            v-model="general_data.submit_before"
            vs-value="submit_before_false"
            name="submit_before"
            >No</vs-radio
          >
        </li>
      </ul>
    </div>

    <!-- Please upload the following documents :  -->
    <div class="vx-col w-full mt-5">
      <ul class="vx-row mt-2">
        <li class="vx-col w-full">
          <label>
            <b>Please upload the following documents :</b>
          </label>
        </li>
        <li class="vx-col md:w-1/2 w-full">
          <div class="centerx">
            <span>{{ $t("AppReg.Student") }}</span>
            <vs-input
              type="file"
              accept="image/*"
              @change="onChange1"
            ></vs-input>
            <div id="preview">
              <img
                v-if="imageUrl1"
                :src="imageUrl1"
                style="height: 150px; width: 150px"
              />
            </div>
          </div>
        </li>
        <li class="vx-col md:w-1/2 w-full">
          <div class="centerx">
            <span>{{ $t("AppReg.BirthCertificateForTheChild") }}</span>
            <vs-input
              type="file"
              accept="image/*"
              @change="onChange2"
            ></vs-input>
            <div id="preview">
              <img
                v-if="imageUrl2"
                :src="imageUrl2"
                style="height: 150px; width: 150px"
              />
            </div>
          </div>
        </li>
        <li class="vx-col md:w-1/2 w-full">
          <div class="centerx">
            <span>{{ $t("AppReg.FatherID") }}</span>
            <vs-input
              type="file"
              accept="image/*"
              @change="onChange3"
            ></vs-input>
            <div id="preview">
              <img
                v-if="imageUrl3"
                :src="imageUrl3"
                style="height: 150px; width: 150px"
              />
            </div>
          </div>
        </li>
        <li class="vx-col md:w-1/2 w-full">
          <div class="centerx">
            <span>{{ $t("AppReg.MotherID") }}</span>
            <vs-input
              type="file"
              accept="image/*"
              @change="onChange4"
            ></vs-input>
            <div id="preview">
              <img
                v-if="imageUrl4"
                :src="imageUrl4"
                style="height: 150px; width: 150px"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Hint -->
    <div class="vx-col w-full mt-8 mb-5">
      <p class="text-center">
        <b>
          By hitting the submit button you are aware that this application does
          not guarantee a place at {{ siteTitle }}.
        </b>
      </p>
    </div>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import _ from 'lodash'
import { mapState } from 'vuex'
import { gmapApi } from 'vue2-google-maps'
//////////////////////////////////////
import { OnlineAdmissionService } from './_services'
import commonData from './common_data.js'
import { Validator } from 'vee-validate'

const isUpload = {
  getMessage (field, params, data) {
    return 'Something went wrong'
  },
  validate (value, { file }) {
    return file != ''
  }
}
const paramNames = ['file']

Validator.extend('uploded', isUpload, {
  paramNames //  pass it in the extend options.
})

export default {
  name: 'register-general-data',
  inject: ['$validator'],
  props: {
    general_data: {
      type: Object
    }
  },
  data () {
    return {
      gMapCenter: { lat: 10, lng: 10 },
      markers: [
        {
          position: { lat: 29.99011541950201, lng: 31.132023250770214 },
          infoText: 'Marker 1'
        }
      ],
      currentPlace: null,
      image1: null,
      imageUrl1: null,
      image2: null,
      imageUrl2: null,
      image3: null,
      imageUrl3: null,
      image4: null,
      imageUrl4: null,
      // upload files
      show_upload_button: false,
      acceptedFiles: '.jpeg,.jpg,.png,.pdf',
      limitfiles: 1,
      // Common Array
      religions: commonData.religions,
      countries: commonData.countries,
      mobile_keys: commonData.mobile_keys,
      nationalities_all: commonData.nationalities,
      tel_keys: commonData.tel_keys,
      relationships: commonData.relationships,
      name_signs: commonData.name_signs,
      // map
      map_popupActive: false,
      // health_problem
      health_problem: '',
      health_problem_detials: '',

      //   submit_before
      submit_before: 'submit_before_yes'
    }
  },
  methods: {
    setPlace (place) {
      this.currentPlace = place

      this.markers[0].position.lat = this.currentPlace.geometry.location.lat()
      this.markers[0].position.lng = this.currentPlace.geometry.location.lng()

    },
    //for google map
    googleAddress () {
      this.loaded = true
      if (this.currentPlace) {
        this.setPlace
      }
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      else if (navigator.geolocation) {
        this.getGoogleCurrent()
      } else {
        this.error = this.$t('form.geolocation_is_not_supported')
      }

      return this.loaded
    },
    getGoogleCurrent () {
      navigator.geolocation.getCurrentPosition(
        position => {
          (this.markers[0].position.lat = position.coords.latitude),
          (this.markers[0].position.lng = position.coords.longitude)
        },
        error => {
        }
      )
    },
    onChange1 (e) {
      const file = e.target.files[0]
      this.image1 = file
      this.imageUrl1 = URL.createObjectURL(file)
      //////////////////////////////////////
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      formData.append('directory', '/student/studentImage')
      formData.append('imageData', this.general_data.files.student_photo.url)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      //////////////////////////////

      OnlineAdmissionService.updateImage(formData, config).then(response => {
        this.general_data.files.student_photo.url = response.data
      })
    },
    onChange2 (e) {
      const file = e.target.files[0]
      this.image2 = file
      this.imageUrl2 = URL.createObjectURL(file)
      //////////////////////////////////////
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      formData.append('directory', '/student/BirthCertificateForTheChildImage')
      formData.append(
        'imageData',
        this.general_data.files.birth_certificate.url
      )
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      //////////////////////////////

      OnlineAdmissionService.updateImage(formData, config).then(response => {
        this.general_data.files.birth_certificate.url = response.data

      })
    },
    onChange3 (e) {
      const file = e.target.files[0]
      this.image3 = file
      this.imageUrl3 = URL.createObjectURL(file)
      //////////////////////////////////////
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      formData.append('directory', '/student/FatherImage')
      formData.append('imageData', this.general_data.files.father_ID.url)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      //////////////////////////////

      OnlineAdmissionService.updateImage(formData, config).then(response => {
        this.general_data.files.father_ID.url = response.data

      })
    },
    onChange4 (e) {
      const file = e.target.files[0]
      this.image4 = file
      this.imageUrl4 = URL.createObjectURL(file)
      //////////////////////////////////////
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      formData.append('directory', '/student/MotherImage')
      formData.append('imageData', this.general_data.files.mother_ID.url)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      //////////////////////////////

      OnlineAdmissionService.updateImage(formData, config).then(response => {
        this.general_data.files.mother_ID.url = response.data

      })
    },
    // Open map
    getPostion (location) {
      this.general_data.map.position = location.position
      // this.map_center = this.general_data.map.position;
    },
    savePosition () {
      this.general_data.map.address = `${this.markers[0].position.lat},${this.markers[0].position.lng}`
      this.general_data.map.position = this.markers[0].position
      this.map_popupActive = false
    },

    // Upload
    successUpload () {
      this.$vs.notify({ color: 'success', text: 'Upload Success' })
    },
    errorUpload () {
      this.$vs.notify({
        color: 'danger',
        text: 'Wrong file type !',
        title: 'error'
      })
    },

    // Add Elemets to Array Value
    pushElmsValue (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          value: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Add Elemets to Array Value
    pushNums (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.num === elm_target.num && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          key: '',
          num: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },

    // Remove Elemets to Array Values
    shiftElm (elm_array, target_id) {
      for (let i = 0; i < elm_array.length; i++) {
        const element = elm_array[i]
        if (element.id === target_id) {
          elm_array.splice(i, 1)
        }
      }
    },

    // Push emergencies
    pushEmergencies () {
      this.general_data.emergencies.push({
        id: 1,
        full_name: {
          sign: 'Mr',
          name: ''
        },
        address: {
          city: '',
          country: 'egypt',
          district: '',
          street: ''
        },
        mobile: [
          {
            id: 0,
            key: '010',
            num: ''
          }
        ],
        tel: [
          {
            id: 0,
            key: '02',
            num: ''
          }
        ],
        relationship: ''
      })
    },

    // Get file name
    // getFileName (model_target) {
    //   const fileData =  event.target.files[0]
    //   model_target = `${fileData.url}`
    // },
    setFileToData (name) {
      this.general_data.files[name].file = event.target.files
      this.$validator
        .validate('student_photo', event.target.files)
        .then(valid => {
          if (!valid) {
            // alert('not valid')
          }
        })
    },
    deletFile (name) {
      this.general_data.files[name].file = null
    },
    getCities (indexOfCountry) {
      const selectedCountry = this.countries.find(country => {
        return (
          country.id ===
          this.general_data.emergencies[indexOfCountry].address.country
        )
      })
      return selectedCountry.cities
    },
    getDistricts (indexOfCity) {
      const selectedCity = this.getCities(indexOfCity).find(city => {
        return (
          city.id === this.general_data.emergencies[indexOfCity].address.city
        )
      })
      if (!selectedCity) return ''
      return selectedCity.districts
    }
  },
  components: {},
  computed: {

  },
  created () {
    this.general_data.emergencies[0].address.country = ''
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/vuexy/extraComponents/autocomplete";
.vx-input-group-default {
  .vue-simple-suggest.designed {
    .suggest-item span {
      text-transform: capitalize;
    }
    .input-wrapper input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      text-transform: capitalize;
    }
  }
}
.con-upload {
  position: relative;

  min-height: 150px;
  .con-img-upload {
    overflow: hidden;
    min-height: 240px;

    .con-input-upload {
      position: absolute;
      z-index: 1;
    }
    .img-upload:not(.removeItem) {
      z-index: 2;
    }
  }
}
</style>

