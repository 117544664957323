// import  Api from "./Api";

import axios from 'axios'


// this function to treat with user as api service
export const OnlineAdmissionService = {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  uploadeImage,
  updateImage,
  activeRound,
  nationalities
}

function step1 (data) {
  return axios.post('application/save', data)
}

function step2 (data) {
  return axios.post('application/save', data)
}

function step3 (data) {
  return axios.post('application/save', data)
}

function step4 (data) {
  return axios.post('application/save', data)
}

function step5 (data) {
  return axios.post('application/save', data)
}

function step6 (data) {
  return axios.post('application/save', data)
}

function step7 (data) {
  const formData = new FormData()
  for (const name in data) {
    if (data.hasOwnProperty(name) && (name != 'emergency_contact' || name != 'location'))  {

      formData.append(name, data[name])
    }
  }
  formData.append('emergency_contact', JSON.stringify(data.emergency_contact))
  formData.append('location', JSON.stringify(data.location))
  // for(var pair of formData.entries()) {
  //  }
  return axios.post('application/save', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}
function uploadeImage (imageData, config) {
  return axios.post('/common/UploadeImage', imageData, config)
}
function updateImage (imageData, config) {
  return axios.post('/common/updateImage', imageData, config)
}
function activeRound () {
  return axios.post('/round/activeRound')
}
function nationalities () {
  return axios.post('/common/nationalities')
}
