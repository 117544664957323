<template >
  <div class="vx-row">
    <!-- martial_status -->
    <div class="w-full mt-5">
      <ul class="mt-2 w-full">
        <li>
          <vs-radio
            v-model="marital_data.status"
            name="martial_status"
            vs-value="married_couple"
            v-validate="'required'"
            @change="marital_data.custody.who=''"
          >We are a married couple</vs-radio>
        </li>
        <li>
          <vs-radio
            v-model="marital_data.status"
            vs-value="divorced"
            name="martial_status"
            @change="marital_data.custody.who=''"
          >Divorced</vs-radio>
        </li>
        <li>
          <vs-radio
            v-model="marital_data.status"
            vs-value="widowed_father"
            name="martial_status"
            @change="marital_data.custody.who=''"
          >Widowed father</vs-radio>
        </li>
        <li>
          <vs-radio
            v-model="marital_data.status"
            vs-value="widowed_mother"
            name="martial_status"
            @change="marital_data.custody.who=''"
          >Widowed mother</vs-radio>
        </li>
      </ul>
    </div>

    <!-- Custody-->
    <div v-if="marital_data.status!=='married_couple'">
      <div class="w-full mt-5">
        <label>Do you have the Custody ?</label>
        <ul class="mt-2 w-full">
          <li>
            <vs-radio
              v-model="marital_data.custody.who"
              name="custody"
              vs-value="custody_widowed_father"
              v-if="marital_data.status==='widowed_father'"
              v-validate="'required'"
            >Yes</vs-radio>
          </li>
          <li>
            <vs-radio
              v-model="marital_data.custody.who"
              name="custody"
              vs-value="custody_widowed_mother"
              v-if="marital_data.status==='widowed_mother'"
            >Yes</vs-radio>
          </li>

          <li>
            <vs-radio
              v-model="marital_data.custody.who"
              name="custody"
              vs-value="custody_divorced_mother"
              v-if="marital_data.status==='divorced'"
            >Mother</vs-radio>
          </li>
          <li>
            <vs-radio
              v-model="marital_data.custody.who"
              name="custody"
              vs-value="custody_divorced_father"
              v-if="marital_data.status==='divorced'"
            >father</vs-radio>
          </li>
          <li>
            <vs-radio v-model="marital_data.custody.who" name="custody" vs-value="others">No</vs-radio>
          </li>
        </ul>
        <span class="text-danger">{{ errors.first('step-6.custody') }}</span>
      </div>
      <!-- If others custody -->
      <div v-if="marital_data.custody.who ==='others'">
        <div class="vx-row mt-5 w-full">
          <!-- First name -->
          <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-1/2 w-full mt-5">
            <label>First Name *</label>
            <vs-input
              @keypress="acceptOnlyLetters"
              @keydown.space="allowOnlyOneSpace"
              v-model="marital_data.custody.others.name.first"
              class="w-full"
              name="first_name"
              placeholder="In English"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first('step-6.first_name') }}</span>
            <span
              v-if="!errors.first('step-6.first_name')"
              class="text-danger text-sm"
            >{{ extraValidationErrors.first_name }}</span>
          </div>
          <!-- Middle name -->
          <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-1/2 w-full mt-5">
            <label>Middle Name (father's)</label>
            <vs-input
              @keypress="acceptOnlyLetters"
              @keydown.space="allowOnlyOneSpace"
              v-model="marital_data.custody.others.name.middle"
              name="middle_name"
              class="w-full"
              placeholder="In English"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first('step-6.middle_name') }}</span>
            <span
              v-if="!errors.first('step-6.middle_name')"
              class="text-danger text-sm"
            >{{ extraValidationErrors.middle_name }}</span>
          </div>
          <!-- Last name -->
          <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-1/2 w-full mt-5">
            <label>Last Name (Family)</label>
            <vs-input
              @keypress="acceptOnlyLetters"
              @keydown.space="allowOnlyOneSpace"
              v-model="marital_data.custody.others.name.last"
              name="last_name"
              class="w-full"
              placeholder="In English"
              v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first('step-6.last_name') }}</span>
            <span
              v-if="!errors.first('step-6.last_name')"
              class="text-danger text-sm"
            >{{ extraValidationErrors.last_name }}</span>
          </div>
          <!-- Birth date -->
          <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
            <label>Birth date *</label>
            <datepicker
              placeholder="Date"
              v-model="marital_data.custody.others.birth_date"
              v-validate="'required'"
              name="birth_date"
              :disabled-dates="disabledDates"
            ></datepicker>
            <span class="text-danger">{{ errors.first('step-6.birth_date') }}</span>
          </div>
          <!-- Gender -->
          <div class="vx-col lg:w-1/2 md:w-1/2 w-full mt-5">
            <label class>Gender</label>
            <ul class="vx-row mt-2">
              <li class="vx-col w-1/2">
                <vs-radio
                  v-model="marital_data.custody.others.gender"
                  vs-value="female"
                  name="gender"
                >Female</vs-radio>
              </li>
              <li class="vx-col w-1/2">
                <vs-radio
                  v-model="marital_data.custody.others.gender"
                  vs-value="male"
                  name="gender"
                >Male</vs-radio>
              </li>
            </ul>
          </div>
          <!-- Religion -->
          <div class="vx-col w-full mt-5">
            <label class>Religion</label>
            <vs-select
              v-model="marital_data.custody.others.religion"
              name="religion"
              class="w-full select-large"
              v-validate="'required'"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in religions"
                class="w-full"
              />
            </vs-select>
            <span class="text-danger">{{ errors.first('step-6.religion') }}</span>
          </div>
          <!-- Nationality -->
          <div class="vx-col w-full mt-5">
            <label>Nationality</label>

            <vue-simple-suggest
              placeholder="In English"
              v-model="marital_data.custody.others.nationality"
              :list="nationalitiesSuggestionList"
              :filter-by-query="true"
              v-validate="'required'"
              class="w-full"
              mode="input"
              name="nationality"
            ></vue-simple-suggest>
            <span
              class="text-danger text-sm"
              v-show="errors.has('step-6.nationality')"
            >{{ errors.first('step-6.nationality') }}</span>
          </div>
          <!-- Address -->
          <div class="centerx w-full mt-5 vx-col">
            <div class="vx-row">
              <label class="md:w-1/6 w-full vx-col pr-0">Address</label>
              <vs-checkbox
                v-model="marital_data.custody.others.sameAddress"
                class="md:w-2/3 w-full vx-col"
              >Same address as my child</vs-checkbox>
            </div>

            <div class="vx-row mt-4" v-if="marital_data.custody.others.sameAddress===false">
              <!-- Country -->
              <vs-select
                v-model="martial_data.custody.others.address.country"
                @change="martial_data.custody.others.address.city = ''; martial_data.custody.others.address.district = '';"
                name="address_country"
                class="select-small vx-col md:w-1/3 mb-3 w-full"
                placeholder="Country"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id"
                  :text="item.name"
                  v-for="(item, index) in countries"
                  class="w-full"
                />
              </vs-select>

              <!-- city -->
              <vs-select
                v-if="martial_data.custody.others.address.country"
                v-model="martial_data.custody.others.address.city"
                @change="martial_data.custody.others.address.district = ''"
                name="address_city"
                class="select-small vx-col md:w-1/3 mb-3 w-full"
                placeholder="city"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id"
                  :text="item.name"
                  v-for="(item,index) in cities"
                  class="w-full"
                />
              </vs-select>

              <!-- district -->
              <vs-select
                v-if="martial_data.custody.others.address.city && martial_data.custody.others.address.country"
                v-model="martial_data.custody.others.address.district"
                name="address_district"
                class="select-small vx-col md:w-1/3 mb-3 w-full"
                placeholder="district"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id"
                  :text="item.name"
                  v-for="(item, index) in districts"
                  class="w-full"
                />
              </vs-select>

              <!-- street -->
              <vs-input
                @keypress="acceptOnlyLetters"
                @keydown.space="allowOnlyOneSpace"
                placeholder="Street"
                v-model="marital_data.custody.others.address.street"
                name="address_street"
                class="vx-col mb-3 w-full"
                v-validate="'required'"
              />
              <span class="text-danger ml-2 vx-col">{{ errors.first('step-6.address_street')}}</span>
              <span class="text-danger ml-2 vx-col">{{ errors.first('step-6.address_country')}}</span>
              <span class="text-danger ml-2 vx-col">{{ errors.first('step-6.address_city')}}</span>
              <span class="text-danger ml-2 vx-col">{{ errors.first('step-6.address_district')}}</span>
              <span
                v-if="!errors.first('step-6.address_street')"
                class="text-danger text-sm vx-col"
              >{{ extraValidationErrors.address_street }}</span>
            </div>
          </div>
          <!--  mobile -->
          <div class="centerx w-full mt-5 vx-col">
            <label class>Mobile</label>
            <!-- Key -->
            <div class="vx-row">
              <vs-select
                v-model="marital_data.custody.others.mobile.key"
                name="mobile_key"
                class="select-small vx-col w-1/4 mb-3 pr-0"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item,index) in mobile_keys"
                  class="w-full"
                />
              </vs-select>

              <!-- number -->
              <!-- @change="marital_data.custody.others.mobile.num=(marital_data.custody.others.mobile.num).length > 8 ?  `${marital_data.custody.others.mobile.num.substring(3)}` : `${marital_data.custody.others.mobile.num}`" -->
              <vs-input
                @keypress="acceptOnlyNumbers"
                placeholder="mobile"
                v-model="marital_data.custody.others.mobile.num"
                class="vx-col w-3/4 mb-3 pl-1"
                name="mobile_num"
                v-validate="'required|length:8'"
              />
            </div>
            <span class="text-danger text-sm">
              {{ errors.first('step-6.mobile_num')
              }}
            </span>
            <span
              v-if="!errors.first('step-6.mobile_num')"
              class="text-danger text-sm"
            >{{ extraValidationErrors.mobile_num }}</span>
          </div>
          <!--   Tel -->
          <div class="centerx w-full mt-5 vx-col">
            <label class>Telephone</label>
            <div
              class="repeater"
              v-for="(tel,index) in marital_data.custody.others.tel"
              :key="tel.id=index+1"
            >
              <div class="vx-row repeater_elm mb-3">
                <!-- Key -->
                <vs-select
                  v-model="tel.key"
                  name="tel_key"
                  class="select-small w-1/4 repend-text border-0 vx-col pr-0"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item,index) in tel_keys"
                    class="w-full"
                  />
                </vs-select>

                <vx-input-group
                  class="vx-col w-3/4 pl-1"
                  prependClasses="border border-solid border-grey border-r-0"
                >
                  <!-- number -->
                  <vs-input
                    @keypress="acceptOnlyNumbers"
                    placeholder="Landline"
                    v-model="tel.num"
                    class="w-full"
                    name="tel_num"
                    @change="tel.num=(tel.num).length > 8 ?  `${tel.num.substring(((tel.num).length )-8)}` : `${tel.num}`"
                  />
                  <!-- Add Another -->
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="pushNums(marital_data.custody.others.tel,tel)"
                      ></vs-button>
                      <vs-button
                        type="border"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-minus"
                        @click="shiftElm(marital_data.custody.others.tel,tel.id)"
                        v-if="marital_data.custody.others.tel.length > 1"
                      ></vs-button>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </div>
            <span class="text-danger text-sm">{{ errors.first('step-6.tel_num') }}</span>
            <span
              v-if="!errors.first('step-6.tel_num')"
              class="text-danger text-sm"
            >{{ extraValidationErrors.tel_num }}</span>
          </div>
          <!--  Email -->
          <div class="vx-col w-full mt-5">
            <div class="vx-row">
              <label class="md:w-1/4 w-full vx-col pr-0">Email*</label>
              <vs-checkbox
                v-model="marital_data.custody.others.sameOfficalEmail"
                class="md:w-2/3 w-full vx-col"
              >same official mailing email</vs-checkbox>
              <div
                class="vx-col mt-3 w-full"
                v-if="marital_data.custody.others.sameOfficalEmail===false"
              >
                <vs-input
                  type="email"
                  placeholder="Please Enter your email"
                  v-model="marital_data.custody.others.email"
                  name="email"
                  class="w-full"
                  v-validate="'required|email'"
                />
                <span class="text-danger">{{ errors.first('step-6.email')}}</span>
              </div>
            </div>
          </div>
          <!-- Relationship -->
          <div class="vx-col w-full mt-5">
            <label>Relationship *</label>

            <vs-select
              v-model="marital_data.custody.others.relationship"
              name="relationship"
              class="select-small w-full"
              v-validate="'required'"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item,index) in relationships"
                class="w-full"
              />
            </vs-select>

            <span class="text-danger">{{ errors.first('step-6.relationship')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Datepicker from 'vuejs-datepicker'
import commonData from './common_data.js'
export default {
  name: 'register-marital-data',
  inject: ['$validator'],
  props: {
    marital_data: {
      type: Object
    }
  },
  data () {
    return {
      extraValidationErrors: {
        first_name: null,
        middle_name: null,
        last_name: null,
        address_street: null,
        mobile_num: null,
        tel_num: null
      },
      // Common

      religions: commonData.religions,
      countries: commonData.countries,
      mobile_keys: commonData.mobile_keys,
      nationalities_all: commonData.nationalities,
      tel_keys: commonData.tel_keys,
      relationships: commonData.relationships,

      // Feilds

      //   Address
      sameAddress: true,

      //   Contacts
      mobile_key: '010',
      moblie_num: '',

      sameOfficalEmail: true,

      custody_others: {
        id: this.counter,
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        birth_date: '',
        gender: 'female',
        religion: '',
        nationality_choosen: [
          {
            id: this.counter,
            value: ''
          }
        ],
        address_city: '',
        address_country: 'egypt',
        address_district: '',
        addree_street: '',
        mobile_full_num: `${this.mobile_key}-${this.moblie_num}`,
        tel_nums: [
          {
            id: this.counter,
            key: '02',
            num: '',
            full_num: `${this.tel_key}-${this.tel_num}`
          }
        ],
        relationship: ''
      },
      custody_value () {
        return this.marital_data.status === 'widowed_father'
          ? 'widowed_father'
          : 'widowed_mother'
      },
      //   Disable Dates  for Birthdate
      disabledDates: {
        from: new Date()
      }
    }
  },
  methods: {
    acceptOnlyLetters (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Upper Case Letters
      if (charCode >= 65 && charCode <= 90) return true

      // Lower Case Letters
      if (charCode >= 97 && charCode <= 122) return true

      // Strees Address Allow Numbers
      if (field_name === 'address_street' && charCode >= 48 && charCode <= 57) return true

      // Space Charachter
      if (charCode === 32) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Letters and Spaces are the only allowed characters'
      newChar.preventDefault()
    },
    acceptOnlyNumbers (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Numbers Characters
      if (charCode >= 48 && charCode <= 57) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Only Numbers Are Allowed in this field'
      newChar.preventDefault()
    },
    allowOnlyOneSpace (space) {
      const field_name = space.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const value = space.originalTarget.value
      if (value.length !== 0 && value[value.length - 1] !== ' ') return true
      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Extra Space Error",
      //   text: "Only one space is allowed",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] = 'Only one space is allowed'
      space.preventDefault()
    },
    // Add Elemets to Array Value
    pushElmsValue (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          value: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Add Elemets to Array Value
    pushNums (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.num === elm_target.num && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          key: '',
          num: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },

    // Remove Elemets to Array Values
    shiftElm (elm_array, target_id) {
      for (let i = 0; i < elm_array.length; i++) {
        const element = elm_array[i]
        if (element.id === target_id) {
          elm_array.splice(i, 1)
        }
      }
    },

    // Autocomplete National List
    nationalitiesSuggestionList () {
      return this.nationalities_all
    }
  },
  components: {
    Datepicker,
    VueSimpleSuggest
  },
  computed: {
    cities () {
      const selectedCountry = this.countries.find(country => {
        return country.id === this.marital_data.custody.others.address.country
      })
      return selectedCountry.cities
    },
    districts () {
      const selectedCity = this.cities.find(city => {
        return city.id === this.marital_data.custody.others.address.city
      })
      if (!selectedCity) return ''
      return selectedCity.districts
    }
  },
  created () {
    this.marital_data.custody.others.address.country = ''
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/vuexy/extraComponents/autocomplete";
.vx-input-group-default {
  .vue-simple-suggest.designed {
    .suggest-item span {
      text-transform: capitalize;
    }
    .input-wrapper input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      text-transform: capitalize;
    }
  }
}
</style>

