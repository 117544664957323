// Common arrays
export default {
  religions: [
    {
      text: 'Islam',
      value: 'islam'
    },
    {
      text: 'Christianity',
      value: 'christianity'
    }
  ],
  cities: [
    {
      text: 'Cairo',
      value: 'cairo'
    },
    {
      text: 'Alex',
      value: 'alex'
    }
  ],
  countries: [
    {
      name: 'Egypt',
      id: 'egypt',
      cities: [
        {
          name: 'Cairo',
          id: 'cairo',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            },
            {
              name: 'District 3',
              id: 'district_3'
            }
          ]
        },
        {
          name: 'Alexandria',
          id: 'alexandria',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Ismailia',
          id: 'ismailia',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Port Said',
          id: 'port_said',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Luxor',
          id: 'luxor',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Suez',
          id: 'suez',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Kafr ash Shaykh',
          id: 'kafr_ash_shaykh',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Al Ghardaqah',
          id: 'al_ghardaqah',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        },
        {
          name: 'Damietta',
          id: 'damietta',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        }
      ]
    },
    {
      name: 'USA',
      id: 'usa',
      cities: [
        {
          name: 'Cairo_USA',
          id: 'cairo_usa',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            },
            {
              name: 'District 3',
              id: 'district_3'
            }
          ]
        },
        {
          name: 'Hello USA',
          id: 'hello_usa',
          districts: [
            {
              name: 'District 1',
              id: 'district_1'
            },
            {
              name: 'District 2',
              id: 'district_2'
            }
          ]
        }
      ]
    }
  ],
  mobile_keys: [
    {
      text: '010',
      value: '010'
    },
    {
      text: '011',
      value: '011'
    },
    {
      text: '012',
      value: '012'
    },
    {
      text: '015',
      value: '015'
    }
  ],
  nationalities: ['italian', 'egyptian', 'british'],
  tel_keys: [
    {
      text: '02',
      value: '02'
    },
    {
      text: '04',
      value: '04'
    }
  ],
  divisions: [
    {
      text: 'Division1',
      value: 'divisions-1'
    },
    {
      text: 'Division2',
      value: 'divisions-2'
    }
  ],
  grades: [
    {
      text: 'grade1',
      value: 'grade-1'
    },
    {
      text: 'grade2',
      value: 'grade-2'
    }
  ],
  school_years: [
    {
      text: 'year 1',
      value: 'year 1'
    },
    {
      text: 'year 2',
      value: 'year 2'
    },
    {
      text: 'year 3',
      value: 'year 3'
    }
  ],
  school_month_start_options: [
    {
      text: 'January',
      value: 'January'
    },
    {
      text: 'February',
      value: 'February'
    },
    {
      text: 'March',
      value: 'March'
    },
    {
      text: 'April',
      value: 'April'
    },
    {
      text: 'May',
      value: 'May'
    },
    {
      text: 'June',
      value: 'June'
    },
    {
      text: 'July',
      value: 'July'
    },
    {
      text: 'August',
      value: 'August'
    },
    {
      text: 'September',
      value: 'September'
    },
    {
      text: 'october',
      value: 'october'
    },
    {
      text: 'November',
      value: 'November'
    },
    {
      text: 'December',
      value: 'December'
    }
  ],
  schools: [
    { text: 'school1', value: 'school-1' },
    { text: 'school2', value: 'school-2' }
  ],
  languages: [
    { text: 'English', value: 'en' },
    { text: 'Arabic', value: 'ar' }
  ],
  fax_keys: [
    {
      text: '02',
      value: '02'
    }
  ],
  business_types: [{ text: 'type 1', value: 'type1' }],
  relationships: ['grandfather', 'grandmother', 'uncle', 'aunt'],
  name_signs: ['Mr', 'Mrs', 'Ms', 'Miss'],
  students_list: [
    {
      application_id: 1,
      name: 'Ahmed Ashraf',
      division: 'Lycee',
      grade: 'Grade 3',
      application_completed: true,
      application_status: 'Recieved',
      last_step: 7
    },
    {
      application_id: 2,
      name: 'Mazen Fathy',
      division: 'Lycee',
      grade: 'Grade 3',
      application_completed: true,
      application_status: 'ShortListed',
      last_step: 7
    },
    {
      application_id: 3,
      name: 'Hesham Mohammed',
      division: 'Lycee',
      grade: 'Grade 3',
      application_completed: true,
      application_status: 'On Assesment',
      last_step: 7
    },
    {
      application_id: 4,
      name: 'Leqaa Ahmed',
      division: 'Lycee',
      grade: 'Grade 3',
      application_completed: true,
      application_status: 'Waiting List',
      last_step: 7
    },
    {
      application_id: 5,
      name: 'Yasser Ali',
      division: 'Lycee',
      grade: 'Grade 3',
      application_completed: true,
      application_status: 'Qualified',
      last_step: 7
    },
    {
      application_id: 6,
      name: 'Taha Essam',
      division: 'Lycee',
      grade: 'Grade 3',
      application_completed: true,
      application_status: 'Disqualified',
      last_step: 6
    },
    {
      application_id: 7,
      name: 'Mazen Mohammed',
      division: 'IB',
      grade: 'Grade 5',
      application_completed: false,
      application_status: null,
      last_step: 5
    },
    {
      application_id: 8,
      name: 'Mazen Mohammed',
      division: 'IB',
      grade: 'Grade 5',
      application_completed: false,
      application_status: null,
      last_step: 3
    },
    {
      application_id: 9,
      name: 'Mazen Mohammed',
      division: 'IB',
      grade: 'Grade 5',
      application_completed: false,
      application_status: null,
      last_step: 2
    }
  ]
}
