<template >
  <div class="vx-row">
    <!-- First name -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        First Name
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyLetters"
        @keydown.space="allowOnlyOneSpace"
        v-model="mother.name.first"
        class="w-full"
        name="first_name"
        placeholder="In English"
        v-validate="'required'"
      />
      <span class="text-danger">{{ errors.first('step-4.first_name') }}</span>
      <span
        v-if="!errors.first('step-4.first_name')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.first_name }}</span>
    </div>
    <!-- Middle name -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        Middle Name (father's)
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyLetters"
        @keydown.space="allowOnlyOneSpace"
        v-model="mother.name.middle"
        name="middle_name"
        class="w-full"
        placeholder="In English"
        v-validate="'required'"
      />
      <span class="text-danger">{{ errors.first('step-4.middle_name') }}</span>
      <span
        v-if="!errors.first('step-4.middle_name')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.middle_name }}</span>
    </div>
    <!-- Last name -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        Last Name (Family)
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyLetters"
        @keydown.space="allowOnlyOneSpace"
        v-model="mother.name.last"
        name="last_name"
        class="w-full"
        placeholder="In English"
        v-validate="'required'"
      />
      <span class="text-danger">{{ errors.first('step-4.last_name') }}</span>
      <span
        v-if="!errors.first('step-4.last_name')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.last_name }}</span>
    </div>
    <!-- Birth date -->
    <div class="vx-col xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-5">
      <label>
        Birth date
        <span class="text-danger">*</span>
      </label>
      <datepicker
        placeholder="Date"
        v-model="mother.birth_date"
        v-validate="'required'"
        name="birth_date"
        :disabled-dates="disabledDates"
      ></datepicker>
      <span class="text-danger">{{ errors.first('step-4.birth_date') }}</span>
    </div>

    <!-- Religion -->
    <div class="vx-col xl:w-1/2 w-full mt-5">
      <label class>
        Religion
        <span class="text-danger">*</span>
      </label>
      <vs-select
        v-model="mother.religion"
        name="religion"
        class="w-full select-large"
        v-validate="'required'"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item,index) in religions"
          class="w-full"
        />
      </vs-select>
      <span class="text-danger">{{ errors.first('step-4.religion') }}</span>
    </div>
    <!-- Nationality -->
    <div class="vx-col w-full mt-5">
      <label>
        Nationality
        <span class="text-danger">*</span>
      </label>
      <div class="vx-col w-full mt-5">
        <v-select
          multiple
          :closeOnSelect="false"
          v-model="mother.nationalities"
          v-validate="'required'"
          class="w-full"
          name="nationality"
          :options="nationalities_all"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <br />
      </div>
      <span
        class="text-danger text-sm"
        v-show="errors.has('step-4.nationality')"
      >{{ errors.first('step-4.nationality') }}</span>
    </div>
    <!--<div class="vx-col w-full mt-5">
      <label>Nationality<span class="text-danger"> * </span></label>
      <div class="repeater" v-for="(national,index) in mother.nationalities" :key="index">
        <vx-input-group class="repeater_elm mb-3" :id="national.id=index+1">
          <vue-simple-suggest
            placeholder="In English"
            v-model="national.value"
            :list="nationalitiesSuggestionList"
            :filter-by-query="true"
            v-validate="'required'"
            class="w-full"
            mode="input"
            name="nationality"
          ></vue-simple-suggest>

          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button
                type="border"
                icon-pack="feather"
                icon="icon-plus"
                @click="pushElmsValue(mother.nationalities,national)"
              ></vs-button>
              <vs-button
                type="border"
                color="danger"
                icon-pack="feather"
                icon="icon-minus"
                @click="shiftElm(mother.nationalities, national.id)"
                v-if="mother.nationalities.length > 1"
              ></vs-button>
            </div>
          </template>
        </vx-input-group>
      </div>
      <span
        class="text-danger text-sm"
        v-show="errors.has('step-4.nationality')"
      >{{ errors.first('step-4.nationality') }}</span>
    </div>-->
    <!-- Address -->
    <div class="centerx w-full mt-5 vx-col">
      <div class="vx-row">
        <label class="md:w-1/6 w-full vx-col pr-0">
          Address
          <span class="text-danger">*</span>
        </label>
        <vs-checkbox
          v-model="mother.sameAddress"
          class="md:w-2/3 w-full vx-col"
        >Same address as my child</vs-checkbox>
      </div>

      <div
        class="vx-row mt-4 w-full"
        v-if="mother.sameAddress===false"
      >
        <!-- Country -->
        <vs-select
          v-model="mother.address.country"
          @change="mother.address.city = ''; mother.address.district = '';"
          name="address_country"
          class="select-small vx-col md:w-1/3 mb-3 w-full"
          placeholder="Country"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in countries"
            class="w-full"
          />
        </vs-select>

        <!-- city -->
        <vs-select
          v-if="mother.address.country"
          v-model="mother.address.city"
          @change="mother.address.district = ''"
          name="address_city"
          class="select-small vx-col md:w-1/3 mb-3 w-full"
          placeholder="city"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item,index) in cities"
            class="w-full"
          />
        </vs-select>

        <!-- district -->
        <vs-select
          v-if="mother.address.city && mother.address.country"
          v-model="mother.address.district"
          name="address_district"
          class="select-small vx-col md:w-1/3 mb-3 w-full"
          placeholder="district"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in districts"
            class="w-full"
          />
        </vs-select>
        <!-- street -->
        <vs-input
          @keypress="acceptOnlyLetters"
          @keydown.space="allowOnlyOneSpace"
          placeholder="Street"
          v-model="mother.address.street"
          name="address_street *"
          class="vx-col mb-3 w-full"
          v-validate="'required'"
        />
        <span class="text-danger">*</span>
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-4.address_street')"
        >
          {{
          errors.first('step-4.address_street') }}
        </span>
        <span
          v-if="!errors.first('step-4.address_street')"
          class="text-danger text-sm vx-col"
        >{{ extraValidationErrors.address_street }}</span>

        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-4.address_country')"
        >
          {{
          errors.first('step-4.address_country') }}
        </span>
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-4.address_city')"
        >
          {{
          errors.first('step-4.address_city') }}
        </span>
        <span
          class="text-danger text-sm vx-col"
          v-if="
            errors.has('step-4.address_district')"
        >
          {{
          errors.first('step-4.address_district') }}
        </span>
      </div>
    </div>
    <!--  mobile -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>
        mobile
        <span class="text-danger">*</span>
      </label>
      <!-- Key -->
      <div class="vx-row">
        <vs-select
          v-model="mother.mobile.key"
          name="mobile_key"
          class="select-small vx-col w-1/4 mb-3 pr-0"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in mobile_keys"
            class="w-full"
          />
        </vs-select>

        <!-- number -->
        <!-- @change="mother.mobile.num=(mother.mobile.num).length > 8 ?  `${mother.mobile.num.substring(3)}` : `${mother.mobile.num}`" -->
        <vs-input
          @keypress="acceptOnlyNumbers"
          placeholder="mobile"
          v-model="mother.mobile.num"
          class="vx-col w-3/4 mb-3 pl-1"
          name="mobile_num"
          v-validate="'required|numeric|length:8'"
        />
      </div>
      <span class="text-danger text-sm">
        {{ errors.first('step-4.mobile_num')
        }}
      </span>
      <span
        v-if="!errors.first('step-4.mobile_num')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.mobile_num }}</span>
    </div>
    <!--   Tel -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>
        Telephone
        <span class="text-danger">*</span>
      </label>
      <div
        class="repeater"
        v-for="(tel,index) in mother.tel"
        :key="tel.id=index+1"
      >
        <div class="vx-row repeater_elm mb-3">
          <!-- Key -->
          <vs-select
            v-model="tel.key"
            name="tel_key"
            class="select-small w-1/4 repend-text border-0 vx-col pr-0"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in tel_keys"
              class="w-full"
            />
          </vs-select>

          <vx-input-group
            class="vx-col w-3/4 pl-1"
            prependClasses="border border-solid border-grey border-r-0"
          >
            <!-- number -->
            <!-- @change="tel.num=(tel.num).length > 8 ?  `${tel.num.substring(((tel.num).length )-8)}` : `${tel.num}`" -->
            <vs-input
              @keypress="acceptOnlyNumbers"
              placeholder="Landline"
              v-model="tel.num"
              class="w-full"
              name="tel_num"
              v-validate="'required|numeric|length:8'"
            />
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="pushNums(mother.tel,tel)"
                ></vs-button>
                <vs-button
                  type="border"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-minus"
                  @click="shiftElm(mother.tel , tel.id)"
                  v-if="mother.tel.length > 1"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <span class="text-danger text-sm">{{ errors.first('step-4.tel_num') }}</span>
      <span
        v-if="!errors.first('step-4.tel_num')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.tel_num }}</span>
    </div>
    <!--  Email -->
    <div class="vx-col w-full mt-5">
      <div class="vx-row">
        <label class="md:w-1/4 w-full vx-col pr-0">
          Contact Email
          <span class="text-danger">*</span>
        </label>
        <vs-checkbox
          v-model="mother.sameOfficalEmail"
          class="md:w-2/3 w-full vx-col"
        >same official mailing email</vs-checkbox>
        <vs-input
          type="email"
          placeholder="Please Enter your email"
          v-model="mother.email"
          name="email"
          class="w-full mt-3 vx-col"
          v-if="mother.sameOfficalEmail===false"
          v-validate="'email|required'"
        />
      </div>
    </div>

    <!-- mother Id  -->
    <div class="vx-col w-full mt-5">
      <label>
        mother ID
        <span class="text-danger">*</span>
      </label>
      <vs-input
        @keypress="acceptOnlyNumbers"
        type="text"
        v-model="mother.id_num"
        class="w-full"
        name="id_num"
        v-validate="'required|numeric|length:14'"
        description-text="Passport ID for foreigner"
      />
      <span class="text-danger">{{ errors.first('step-4.id_num') }}</span>
      <span
        v-if="!errors.first('step-4.id_num')"
        class="text-danger text-sm"
      >{{ extraValidationErrors.id_num }}</span>
    </div>
    <!-- Education School   -->
    <div class="vx-col w-full mt-5">
      <label>
        Education School
        <span class="text-danger">*</span>
      </label>
      <vs-input
        type="text"
        v-model="mother.school"
        name="school"
        class="w-full"
        v-validate="'required'"
      />
      <span class="text-danger">{{ errors.first('step-4.school') }}</span>
    </div>
    <!-- Education University    -->
    <div class="vx-col w-full mt-5">
      <label>
        Education University
        <span class="text-danger">*</span>
      </label>
      <vs-input
        type="text"
        v-model="mother.university"
        name="university"
        class="w-full"
        v-validate="'required'"
      />
      <span class="text-danger">{{ errors.first('step-4.university') }}</span>
    </div>
    <!-- Occupation    -->
    <div class="vx-col w-full mt-5">
      <label>
        occupation
        <span class="text-danger">*</span>
      </label>
      <vs-input
        type="text"
        v-model="mother.occupation"
        name="occupation"
        class="w-full"
        v-validate="'required'"
      />
      <span class="text-danger">{{ errors.first('step-4.occupation') }}</span>
    </div>
    <!-- employer    -->
    <div class="vx-col w-full mt-5">
      <label>employer</label>
      <vs-input
        type="text"
        v-model="mother.employer"
        name="mother_employer"
        class="w-full"
      />
    </div>
    <!-- Type of Business    -->
    <div class="vx-col w-full mt-5">
      <label>
        Type of Business
        <span class="text-danger">*</span>
      </label>
      <vs-select
        v-model="mother.business_type"
        name="business_type"
        class="select-small vx-col mb-2 w-full"
        v-validate="'required'"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item,index) in business_types"
          class="w-full"
        />
        <span class="text-danger">{{ errors.first('step-4.business_type') }}</span>
      </vs-select>
    </div>
    <!-- work Address    -->
    <div class="vx-col w-full mt-5">
      <label>work Address</label>
      <vs-input
        type="text"
        v-model="mother.work.address"
        name="work_address"
        class="w-full"
      />
    </div>
    <!-- work mobile -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>Work mobile</label>
      <!-- Key -->
      <div class="vx-row">
        <vs-select
          v-model="mother.work.mobile.key"
          name="mobile_key"
          class="select-small vx-col w-1/4 mb-3 pr-0"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in mobile_keys"
            class="w-full"
          />
        </vs-select>

        <!-- number -->
        <vs-input
          placeholder="mobile"
          v-model="mother.work.mobile.num"
          class="vx-col w-3/4 mb-3 pl-1"
          name="work_moblie_num"
          v-validate="'numeric'"
        />
      </div>
      <span class="text-danger">{{ errors.first('step-4.work_moblie_num') }}</span>
    </div>
    <!--  work Tel -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>Work Telephone</label>
      <div
        class="repeater"
        v-for="(tel,index) in mother.work.tel"
        :key="tel.id=index+1"
      >
        <div class="vx-row repeater_elm mb-3">
          <!-- Key -->
          <vs-select
            v-model="tel.key"
            name="tel_key"
            class="select-small w-1/4 repend-text border-0 vx-col pr-0"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in tel_keys"
              class="w-full"
            />
          </vs-select>

          <vx-input-group
            class="vx-col w-3/4 pl-1"
            prependClasses="border border-solid border-grey border-r-0"
          >
            <!-- number -->
            <!-- @change="tel.num=(tel.num).length > 8 ?  `${tel.num.substring(((tel.num).length )-8)}` : `${tel.num}`" -->
            <vs-input
              placeholder="Landline"
              v-model="tel.num"
              class="w-full"
              name="work_tel_num"
              v-validate="'numeric|length:8'"
            />
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="pushNums(mother.work.tel,tel)"
                ></vs-button>
                <vs-button
                  type="border"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-minus"
                  @click="shiftElm(mother.work.tel , tel.id)"
                  v-if="mother.work.tel.length > 1"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
    </div>
    <!-- work Email -->
    <div class="vx-col w-full mt-5">
      <label>work Email</label>
      <vs-input
        type="email"
        placeholder="Please Enter your email"
        v-model="mother.work.email"
        class="w-full mt-3"
        name="work_email"
        v-validate="'email'"
      />
    </div>
    <span class="text-danger">{{ errors.first('step-4.work_email') }}</span>

    <!--  work Fax -->
    <div class="centerx w-full mt-5 vx-col">
      <label class>Work Fax</label>

      <div
        class="repeater"
        v-for="(fax,index) in mother.work.fax"
        :key="fax.id=index+1"
      >
        <div class="vx-row repeater_elm mb-3">
          <!-- Key -->
          <vs-select
            v-model="fax.key"
            name="fax_key"
            class="select-small w-1/4 repend-text border-0 vx-col pr-0"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in fax_keys"
              class="w-full"
            />
          </vs-select>

          <vx-input-group
            class="vx-col w-3/4 pl-1"
            prependClasses="border border-solid border-grey border-r-0"
          >
            <!-- number -->
            <!-- @change="fax.num=(fax.num).length > 7 ?  `${fax.num.substring(((fax.num).length )-7)}` : `${fax.num}`" -->
            <vs-input
              placeholder="Landline"
              v-model="fax.num"
              class="w-full"
              name="fax_num"
              v-validate="'numeric|length:8'"
            />
            <span class="text-danger">{{ errors.first('step-4.fax_num') }}</span>
            <!-- Add Another -->
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="pushNums(mother.work.fax,fax)"
                ></vs-button>
                <vs-button
                  type="border"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-minus"
                  @click="shiftElm(mother.work.fax , fax.id)"
                  v-if="mother.work.fax.length > 1"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
    </div>
    <!-- school alumni -->
    <div class="vx-col md:w-1/2 w-full mt-5">
      <label class>Are you a school alumni ?</label>
      <ul class="vx-row mt-2">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="mother.school_alumni"
            vs-value="school_alumni_true"
            name="school_alumni"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="mother.school_alumni"
            vs-value="school_alumni_false"
            name="school_alumni"
          >No</vs-radio>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Datepicker from 'vuejs-datepicker'
import commonData from './common_data.js'
import { Validator } from 'vee-validate'
import vSelect from 'vue-select'
export default {
  name: 'register-mother-data',
  inject: ['$validator'],
  props: {
    mother: {
      type: Object
    }
  },
  data () {
    return {
      extraValidationErrors: {
        first_name: null,
        middle_name: null,
        last_name: null,
        address_street: null,
        mobile_num: null,
        tel_num: null,
        id_num: null
      },
      // Common Array
      religions: commonData.religions,
      countries: commonData.countries,
      mobile_keys: commonData.mobile_keys,
      nationalities_all: commonData.nationalities,
      tel_keys: commonData.tel_keys,
      divisions: commonData.divisions,
      grades: commonData.grades,
      schools: commonData.schools,
      school_years: commonData.school_years,
      school_month_start_options: commonData.school_month_start_options,
      fax_keys: commonData.fax_keys,
      business_types: commonData.business_types,

      //   Disable Dates  for Birthdate
      disabledDates: {
        from: new Date()
      }
    }
  },
  methods: {
    acceptOnlyLetters (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Upper Case Letters
      if (charCode >= 65 && charCode <= 90) return true

      // Lower Case Letters
      if (charCode >= 97 && charCode <= 122) return true

      // Strees Address Allow Numbers
      if (field_name === 'address_street' && charCode >= 48 && charCode <= 57) return true

      // Space Charachter
      if (charCode === 32) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Letters and Spaces are the only allowed characters'
      newChar.preventDefault()
    },
    acceptOnlyNumbers (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Numbers Characters
      if (charCode >= 48 && charCode <= 57) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Only Numbers Are Allowed in this field'
      newChar.preventDefault()
    },
    allowOnlyOneSpace (space) {
      const field_name = space.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const value = space.originalTarget.value
      if (value.length !== 0 && value[value.length - 1] !== ' ') return true
      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Extra Space Error",
      //   text: "Only one space is allowed",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] = 'Only one space is allowed'
      space.preventDefault()
    },
    // Add Elemets to Array Value
    pushElmsValue (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          value: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Add Elemets to Array Value
    pushNums (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.num === elm_target.num && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          key: '',
          num: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },

    // Remove Elemets to Array Values
    shiftElm (elm_array, target_id) {
      for (let i = 0; i < elm_array.length; i++) {
        const element = elm_array[i]
        if (element.id === target_id) {
          elm_array.splice(i, 1)
        }
      }
    },

    // Autocomplete National List
    nationalitiesSuggestionList () {
      return this.nationalities_all
    }
  },
  components: {
    Datepicker,
    VueSimpleSuggest,
    Validator,
    vSelect
  },
  computed: {
    cities () {
      const selectedCountry = this.countries.find(country => {
        return country.id === this.mother.address.country
      })
      return selectedCountry.cities
    },
    districts () {
      const selectedCity = this.cities.find(city => {
        return city.id === this.mother.address.city
      })
      if (!selectedCity) return ''
      return selectedCity.districts
    }
  },
  created () {
    this.mother.address.country = ''
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/vuexy/extraComponents/autocomplete";
.vx-input-group-default {
  .vue-simple-suggest.designed {
    .suggest-item span {
      text-transform: capitalize;
    }
    .input-wrapper input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      text-transform: capitalize;
    }
  }
}
</style>

