<template >
  <div class="vx-row">
    <!-- Is this your first time your child join any school ? -->
    <div class="vx-col w-full mt-5">
      <label class>Is this your first time your child join any school ?</label>
      <ul class="vx-row mt-2 w-full">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.first_join"
            vs-value="first_join_true"
            name="first_join"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.first_join"
            vs-value="first_join_false"
            name="first_join"
          >No</vs-radio>
        </li>
      </ul>
    </div>

    <!-- Schools Attended Form   -->
    <div
      class="vx-col w-full mt-5"
      v-if="student_info.first_join.includes('true')"
    >
      <label>
        List all schools attended, most recent first
        <span class="text-danger">*</span>
      </label>
      <!-- School -->
      <div
        class="repeater mb-3"
        v-for="(school,index) in student_info.schools"
        :key="school.id=index+1"
      >
        <!-- School name -->
        <div class="mb-3 w-full">
          <vs-input
            placeholder="School Name * "
            @keypress="acceptOnlyLetters"
            @keydown.space="allowOnlyOneSpace"
            v-model="school.name"
            name="school_name"
            class="w-full"
            v-validate="'required'"
          ></vs-input>
          <span class="text-danger text-sm">{{ errors.first('step-2.school_name') }}</span>
          <span
            class="text-danger text-sm"
            v-if="!errors.first('step-1.school_name')"
          >{{ extraValidationErrors.school_name }}</span>
        </div>
        <!-- Division -->
        <div class="mb-3 w-full">
          <vs-select
            placeholder="Division * "
            v-model="school.division"
            name="division"
            class="select-small w-full"
            v-validate="'required'"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in divisions"
              class="w-full"
            />
          </vs-select>
          <span class="text-danger text-sm">{{ errors.first('step-2.division') }}</span>
        </div>
        <!-- Grade -->
        <div class="mb-3 w-full">
          <vs-select
            placeholder="Last Year Group / Grade * "
            v-model="school.grade"
            name="grade"
            class="select-small w-full"
            v-validate="'required'"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in grades"
              class="w-full"
            />
          </vs-select>
          <span class="text-danger text-sm">{{ errors.first('step-2.grade') }}</span>
        </div>
        <!-- School Year -->
        <div class="mb-3 w-full">
          <vs-select
            placeholder="School Year  (Academic Year) Attended * "
            v-model="school.year"
            name="school_year"
            class="select-small w-full"
            v-validate="'required'"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in school_years"
              class="w-full"
            />
          </vs-select>
          <span class="text-danger text-sm">{{ errors.first('step-2.school_year') }}</span>
        </div>
        <!-- Reason For Leaving -->
        <div class="mb-3 w-full">
          <vs-textarea
            placeholder="Reason for Leaving * "
            v-model="school.leaving_reason"
            name="leaving_reason"
            class="w-full mb-0"
            v-validate="'required'"
          ></vs-textarea>
          <span class="text-danger">{{ errors.first('step-2.leaving_reason') }}</span>
        </div>
        <!-- Actions -->
        <div class="btn-group text-right">
          <vs-button
            type="line"
            icon-pack="feather"
            ize="small"
            @click="pushSchools(student_info.schools,school)"
          >Add Another school</vs-button>
          <vs-button
            type="line"
            icon-pack="feather"
            ize="small"
            color="danger"
            @click="shiftElm(student_info.schools,school.id)"
            v-if="student_info.schools.length>1"
          >Remove</vs-button>
        </div>
      </div>
    </div>

    <!-- Spoken Languages   -->
    <div class="vx-col w-full mt-5">
      <label>
        Spoken Languages
        <span class="text-danger">*</span>
      </label>
      <!-- Mother Tongue -->
      <div class="mt-5">
        <v-select
          multiple
          :closeOnSelect="false"
          placeholder="Mother Tongue "
          v-model="student_info.languages.mother"
          v-validate="'required'"
          class="w-full"
          name="mother_lang"
          label="text"
          :options="languages"
          :reduce="languages=>languages.value"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <span class="text-danger">{{ errors.first('step-2.mother_lang') }}</span>
      </div>

      <!--<div class="mb-3">
        <vs-select
          placeholder="Mother Tongue "
          v-model="student_info.languages.mother"
          name="mother_lang"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in languages"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger">{{ errors.first('step-2.mother_lang') }}</span>
      </div>-->
      <!-- Most commonly spoken at home  -->
      <div class="mb-3">
        <v-select
          multiple
          :closeOnSelect="false"
          placeholder="Most commonly spoken at home"
          v-model="student_info.languages.common"
          name="common_lang"
          v-validate="'required'"
          class="w-full"
          label="text"
          :options="languages"
          :reduce="languages=>languages.value"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <span class="text-danger">{{ errors.first('step-2.common_lang') }}</span>
      </div>
      <!--
      <div class="mb-3">
        <vs-select
          placeholder="Most commonly spoken at home"
          v-model="student_info.languages.common"
          name="common_lang"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in languages"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger">{{ errors.first('step-2.common_lang') }}</span>
      </div>-->
      <!-- First foreign language  -->
      <div class="mb-3">
        <v-select
          multiple
          :closeOnSelect="false"
          placeholder="First foreign language"
          v-model="student_info.languages.first_foregin"
          name="first_foregin_lang"
          v-validate="'required'"
          class="w-full"
          label="text"
          :options="languages"
          :reduce="languages=>languages.value"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <span class="text-danger">{{ errors.first('step-2.first_foregin_lang') }}</span>
      </div>
      <!--- <div class="mb-3">
        <vs-select
          placeholder="First foreign language"
          v-model="student_info.languages.first_foregin"
          name="first_foregin_lang"
          class="select-small w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in languages"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger">{{ errors.first('step-2.first_foregin_lang') }}</span>
      </div>-->
    </div>

    <!-- Is your child Arabic exempted ? -->
    <div class="vx-col w-full mt-5">
      <label class>Is your child Arabic exempted ?</label>
      <ul class="vx-row mt-2 w-full">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.child_exempted"
            vs-value="child_exempted_true"
            name="child_exempted"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.child_exempted"
            vs-value="child_exempted_false"
            name="child_exempted"
          >No</vs-radio>
        </li>
      </ul>
    </div>

    <!-- Has your child ever skipped a grade ? -->
    <div class="vx-col w-full mt-5">
      <label class>Has your child ever skipped a grade ?</label>
      <ul class="vx-row mt-2 w-full">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.child_skipped.do"
            vs-value="child_skipped_true"
            name="child_skipped"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.child_skipped.do"
            vs-value="child_skipped_false"
            name="child_skipped"
          >No</vs-radio>
        </li>
      </ul>
      <!-- If yes -->
      <div
        class="vx-col mt-2"
        v-if="student_info.child_skipped.do.includes('true')"
      >
        <!-- Grade -->
        <vs-select
          placeholder="What Grade"
          v-model="student_info.child_skipped.grade"
          name="child_skipped_grade"
          class="select-small mb-3 w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in grades"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger text-sm">
          {{ errors.first('step-2.grade')
          }}
        </span>
      </div>
    </div>

    <!-- Has your child ever been asked to repeat a year ? -->
    <div class="vx-col w-full mt-5">
      <label class>Has your child ever been asked to repeat a year ?</label>
      <ul class="vx-row mt-2 w-full">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.child_repeat.do"
            vs-value="child_repeat_true"
            name="child_repeat"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.child_repeat.do"
            vs-value="child_repeat_false"
            name="child_repeat"
          >No</vs-radio>
        </li>
      </ul>
      <div
        class="vx-col mt-2"
        v-if="student_info.child_repeat.do.includes('true')"
      >
        <!-- Grade -->
        <vs-select
          placeholder="What Grade"
          v-model="student_info.child_repeat.grade"
          name="child_repeat_grade"
          class="select-small mb-3 w-full"
          v-validate="'required'"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in grades"
            class="w-full"
          />
        </vs-select>
        <span class="text-danger text-sm">
          {{ errors.first('step-2.grade')
          }}
        </span>
      </div>
    </div>
    <!-- Has your child received any learning support services in a pervious school or centre ?  -->
    <div class="vx-col w-full mt-5">
      <label>Has your child received any learning support services in a pervious school or centre ?</label>
      <ul class="vx-row mt-2 w-full">
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.support_services.do"
            vs-value="support_services_true"
            name="support_services"
          >Yes</vs-radio>
        </li>
        <li class="vx-col w-1/2">
          <vs-radio
            v-model="student_info.support_services.do"
            vs-value="support_services_false"
            name="support_services"
          >No</vs-radio>
        </li>
      </ul>
      <!-- If yes -->
      <div
        class="vx-col mt-2 w-full"
        v-if="student_info.support_services.do.includes('true')"
      >
        <!--  support_services details -->
        <vs-textarea
          placeholder="If yes , please provide details / reports"
          v-model="student_info.support_services.details"
          name="support_services_details"
          class="mb-3 w-full"
          v-validate="'required'"
        ></vs-textarea>
        <span class="text-danger text-sm">
          {{ errors.first('step-2.support_services_details')
          }}
        </span>
      </div>
    </div>
    <!-- Reason For Applying  -->
    <div class="vx-col w-full mt-5">
      <label>
        Reason For Applying
        <span class="text-danger">*</span>
      </label>
      <vs-textarea
        placeholder="Reason for Applying "
        v-model="student_info.applying_reason"
        name="applying_reason"
        class="mb-0 w-full"
        v-validate="'required'"
      ></vs-textarea>
      <span class="text-danger text-sm">
        {{ errors.first('step-2.applying_reason')
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Datepicker from 'vuejs-datepicker'
import commonData from './common_data.js'
import vSelect from 'vue-select'
export default {
  name: 'register-student-info',
  inject: ['$validator'],
  props: {
    student_info: {
      type: Object
    }
  },
  data () {
    return {
      extraValidationErrors: {
        school_name: null
      },
      // collapse Type
      // Common

      divisions: commonData.divisions,
      grades: commonData.grades,
      schools: commonData.schools,
      school_years: commonData.school_years,
      school_month_start_options: commonData.school_month_start_options,
      languages: commonData.languages,

      //support_services
      support_services: 'support_services_yes',
      support_services_details: '',
      applying_reason: ''
    }
  },
  methods: {
    acceptOnlyLetters (newChar) {
      const field_name = newChar.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const charCode = newChar.charCode
      // Upper Case Letters
      if (charCode >= 65 && charCode <= 90) return true

      // Lower Case Letters
      if (charCode >= 97 && charCode <= 122) return true

      // School Name Allow Numbers
      if (field_name === 'school_name' && charCode >= 48 && charCode <= 57) return true

      // Space Charachter
      if (charCode === 32) return true

      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Invalid Character",
      //   text: "Letters and Spaces are the only allowed characters",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] =
        'Letters and Spaces are the only allowed characters'
      newChar.preventDefault()
    },
    allowOnlyOneSpace (space) {
      const field_name = space.originalTarget.name
      this.extraValidationErrors[field_name] = null
      const value = space.originalTarget.value
      if (value.length !== 0 && value[value.length - 1] !== ' ') return true
      // Else ==> Prevent
      // this.$vs.notify({
      //   title: "Extra Space Error",
      //   text: "Only one space is allowed",
      //   color: "danger"
      // });
      this.extraValidationErrors[field_name] = 'Only one space is allowed'
      space.preventDefault()
    },
    // Add Elemets to Array Value
    pushElmsValue (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.value === elm_target.value && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          value: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Add Elemets to Array Value
    pushNums (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.num === elm_target.num && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          key: '',
          num: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Push specific elms
    pushSchools (elm_array, elm_target) {
      const match = elm_array.some(elm => {
        return elm.name === elm_target.name && elm.id !== elm_target.id
      })
      if (!match) {
        elm_array.push({
          id: 0,
          name: '',
          division: '',
          grade: '',
          year: '',
          month_start: '',
          leaving_reason: ''
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Selected Before !',
          color: 'danger'
        })
        elm_array.splice(elm_target.id, 1)
      }
    },
    // Remove Elemets to Array Values
    shiftElm (elm_array, target_id) {
      for (let i = 0; i < elm_array.length; i++) {
        const element = elm_array[i]
        if (element.id === target_id) {
          elm_array.splice(i, 1)
        }
      }
    },

    // Autocomplete National List
    nationalitiesSuggestionList () {
      return this.nationalities_all
    }
  },
  components: {
    Datepicker,
    VueSimpleSuggest,
    TabContent,
    FormWizard,
    'v-select': vSelect

  }
}
</script>

