<template>
  <div v-if="validationErrors" style="margin-bottom: 30px">
    <ul class="vs-alert con-vs-alert-danger">
      <li v-for="(value, key, index) in validationErrors">{{ value }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['errors'],
  computed: {
    validationErrors () {
      let errors = Object.values(this.errors)
      errors = errors.flat()
      return errors
    }
  }
}
</script>
