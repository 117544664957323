<!-- ----------------------------------------------------------------------------------------
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
----------------------------------------------------------------------------------------= -->

<template>
  <div
    class="
      h-screen
      flex
      w-full
      bg-img
      vx-row
      no-gutter
      items-center
      justify-center
    "
  >
    <div class="vx-col sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 sm:m-0 m-4">
      <vx-card>
        <div class="full-page-bg-color" slot="no-body">
          <div class="vx-row no-gutter">
            <transition
              @enter="fullmoodClass('enter')"
              @leave="fullmoodClass('leave')"
              name="slide-fade"
            >
              <div
                class="
                  vx-col
                  hidden
                  sm:hidden
                  md:hidden
                  lg:block lg:w-1/3
                  mx-auto
                  self-center
                "
                v-if="fullscreenMood === false"
              >
                <div class="vx-card__title mt-auto mb-5 text-center">
                  <div class="panel1" v-if="activeIndex === 0">first tab</div>
                  <div class="panel1" v-else-if="activeIndex === 1">
                    second tab
                  </div>
                  <div class="panel1" v-else-if="activeIndex === 2">
                    thered tab
                  </div>
                  <div class="panel1" v-else-if="activeIndex === 3">
                    forth tab
                  </div>
                  <div class="panel1" v-else-if="activeIndex === 4">tab 5</div>
                  <div class="panel1" v-else-if="activeIndex === 5">tab 6</div>
                  <div class="panel1" v-else-if="activeIndex === 6">tab 7</div>
                  <p>{{ siteTitle }}</p>
                </div>
                <img
                  alt="register"
                  class="mx-auto w-3/4"
                  src="../../../../assets/images/pages/register.jpg"
                />
              </div>
            </transition>
            <transition name="slide-fade">
              <div :class.sync="wfullClass">
                <div class="px-8 pt-8 register-tabs-container">
                  <!-- Full screen  -->
                  <div
                    class="fullscreen text-right sm:hidden md:hidden lg:block"
                  >
                    <vs-button
                      @click="fullscreenMood = true"
                      class="text-3xl p-5 bg-white"
                      icon="fullscreen"
                      radius
                      type="filled"
                      v-if="fullscreenMood === false"
                    ></vs-button>
                    <vs-button
                      @click="fullscreenMood = false"
                      class="text-3xl p-5 bg-white"
                      icon="fullscreen_exit"
                      radius
                      type="filled"
                      v-if="fullscreenMood === true"
                    ></vs-button>
                  </div>

                  <form-wizard
                    :start-index.sync="activeIndex"
                    :subtitle="null"
                    :title="null"
                    @on-complete="formSubmitted"
                    autocomplete="chrome-off"
                    color="rgba(var(--vs-primary), 1)"
                    finishButtonText="Submit"
                  >
                    <!--BackErrors-->

                    <validation-errors
                      :errors="backeErrors"
                      v-if="backeErrors"
                    ></validation-errors>
                    <!--Student Data -->
                    <tab-content
                      :title="step_title.step_1"
                      :before-change="validateStep1"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-1">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_1 }}</h4>
                        </div>

                        <register-student-data
                          v-on:update="updateDivisionGrade"
                          :student.sync="student"
                          ref="step1"
                        ></register-student-data>
                      </form>
                      <!-- Popup -->
                      <vs-popup
                        :active.sync="studentData_popupActive"
                        class="holamundo"
                        title="We are Sent You an Email"
                      >
                        <p>
                          Please note that we’ve sent you an email containing
                          the access to your profile. You can easily continuo
                          submitting your online form during the next 24 hours
                          starting from now
                        </p>
                      </vs-popup>
                    </tab-content>
                    <!--Student Info -->
                    <tab-content
                      :title="step_title.step_2"
                      :before-change="validateStep2"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-2">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_2 }}</h4>
                        </div>

                        <register-student-info
                          :student_info.sync="student_info"
                        ></register-student-info>
                      </form>
                    </tab-content>
                    <!--Father Data -->
                    <tab-content
                      :title="step_title.step_3"
                      :before-change="validateStep3"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-3">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_3 }}</h4>
                        </div>
                        <register-father-data
                          :father.sync="father"
                        ></register-father-data>
                      </form>
                    </tab-content>
                    <!--  Mother Data -->
                    <tab-content
                      :title="step_title.step_4"
                      :before-change="validateStep4"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-4">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_4 }}</h4>
                        </div>
                        <register-mother-data
                          :mother.sync="mother"
                        ></register-mother-data>
                      </form>
                    </tab-content>
                    <!--  Siblings Data -->
                    <tab-content
                      :title="step_title.step_5"
                      :before-change="validateStep5"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-5">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_5 }}</h4>
                        </div>
                        <register-siblings-data
                          :sibling_data.sync="sibling_data"
                        ></register-siblings-data>
                      </form>
                    </tab-content>
                    <!--  Marital Data -->
                    <tab-content
                      :title="step_title.step_6"
                      :before-change="validateStep6"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-6">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_6 }}</h4>
                        </div>
                        <register-marital-data
                          :marital_data.sync="marital_data"
                        ></register-marital-data>
                      </form>
                    </tab-content>
                    <!--  Other / General Data-->
                    <tab-content
                      :title="step_title.step_7"
                      :before-change="validateStep7"
                      class="mb-5"
                    >
                      <form @submit.prevent="false" data-vv-scope="step-7">
                        <div class="vx-card__title mt-auto mb-5 text-center">
                          <h4 class="mb-2">{{ step_title.step_7 }}</h4>
                        </div>
                        <register-general-data
                          :general_data.sync="general_data"
                        ></register-general-data>
                      </form>
                    </tab-content>
                    <vs-popup
                      :active.sync="formSubmit_popupActive"
                      class="holamundo"
                      title="Submission"
                    >
                      <p>Form Is submitted! Redirecting now...</p>
                    </vs-popup>
                  </form-wizard>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { OnlineAdmissionService } from './_services'

import RegisterStudentData from './RegisterStudentData.vue'
import RegisterStudentInfo from './RegisterStudentInfo.vue'
import RegisterFatherData from './RegisterFatherData.vue'
import RegisterMotherData from './RegisterMotherData.vue'
import RegisterSiblingsData from './RegisterSiblingsData.vue'
import RegisterMaritalData from './RegisterMaritalData.vue'
import RegisterGeneralData from './RegisterGeneralData.vue'

// Validator
import { Validator } from 'vee-validate'
import ValidationErrors from './validation-errors'

const dict = {
  custom: {
    //   'required':'Data is required',
    general: {
      required: 'This is a required Data',
      alpha: 'First name may only contain alphabetic characters'
    },
    first_name: {
      required: 'First name is required',
      alpha: 'First name may only contain alphabetic characters'
    },
    full_name: {
      required: 'Full name is required',
      alpha: 'First name may only contain alphabetic characters'
    },
    middle_name: {
      required: 'Middle name is required',
      alpha: 'Middle name may only contain alphabetic characters'
    },
    last_name: {
      required: 'Last name is required',
      alpha: 'Last name may only contain alphabetic characters'
    },
    email: {
      required: 'Email is required',
      email: 'Please enter valid email'
    },
    birth_date: {
      required: 'Birth Date is required'
    },
    religion: {
      required: 'religion is required'
    },
    nationality: {
      required: 'nationality is required'
    },
    id_num: { required: 'ID is required' },
    // Address
    address_street: {
      required: 'full address is required'
    },
    address_country: {
      required: 'country is required'
    },
    address_city: {
      required: 'city is required'
    },
    address_district: {
      required: 'district is required'
    },
    // Contacts
    mobile_num: {
      required: 'mobile is required'
    },

    tel_num: { required: 'Telephone is required' },
    official_email: { required: 'Official Email is required' },

    // Languages
    mother_lang: {
      required: ' Enter your Mother Languages'
    },
    common_lang: {
      required: ' Enter your Common Languages'
    },
    first_foregin_lang: {
      required: ' Enter your first foregin Languages'
    },
    // Education
    support_services_details: {
      required: 'Enter the details'
    },
    applying_reason: {
      required: 'Enter the reasons'
    },
    leaving_reason: {
      required: 'Enter the reasons'
    },
    school: { required: 'school is required' },
    university: { required: 'university is required' },
    school_name: { required: 'school name is required' },
    division: { required: 'division is required' },
    grade: { required: 'Group / Grade is required' },
    school_year: { required: 'school/Academic year is required' },
    occupation: { required: 'occupation is required' },
    relationship: { required: 'relationship is required' },
    custody: { required: 'custody is required' },
    map_address: { required: 'Address on map  is required' },
    details: { required: 'details  is required' },
    student_photo: { required: 'is required' },
    birth_certificate: { required: 'is required' },
    father_ID_photo: { required: 'is required' },
    mother_ID_photo: { required: 'is required' }
  }
}

// Validator custom messages
Validator.localize('en', dict)

export default {
  $_veeValidate: {
    validator: 'new'
  },

  data () {
    return {
      step_title: {
        step_1: 'Student Data',
        step_2: 'Student Information',
        step_3: 'Father Data',
        step_4: 'Mother Data',
        step_5: 'Siblings Data',
        step_6: 'Marital Data',
        step_7: 'General Data'
      },
      activeIndex: 0,
      admission_data_id: 0,
      // Full Screen
      fullscreenMood: false,
      wfullClass:
        'vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg lg:w-2/3',

      // Popup
      formSubmit_popupActive: false,
      studentData_popupActive: false,

      // Backerrors
      backeErrors: null,
      // student
      student: {
        name: {
          first: '',
          middle: '',
          last: ''
        },

        email: '',
        birth_date: '',
        gender: 'male',
        religion: '',

        //   nationalities
        nationalities: [],

        //   Address
        address: {
          city: '',
          country: 'egypt',
          district: '',
          street: ''
        },
        mobile: {
          key: '010',
          num: ''
        },
        tel: [
          {
            id: 0,
            key: '02',
            num: ''
          }
        ],
        official_email: '',

        //   School data
        id_num: '',
        school: '',
        division: '',
        grade: '',
        school_year: '',
        school_month_start: ''
      },
      // Student_info
      student_info: {
        first_join: 'first_join_true',
        schools: [
          {
            id: 0,
            name: '',
            division: '',
            grade: '',
            year: '',
            month_start: '',
            leaving_reason: ''
          }
        ],
        languages: {
          mother: [],
          common: [],
          first_foregin: []
        },
        child_exempted: 'child_exempted_true',
        child_skipped: {
          do: 'child_skipped_false',
          grade: ''
        },
        child_repeat: {
          do: 'child_repeat_false',
          grade: ''
        },
        support_services: {
          do: 'support_services_false',
          details: ''
        },
        applying_reason: ''
      },
      //Father
      father: {
        name: {
          first: '',
          middle: '',
          last: ''
        },

        email: '',
        birth_date: '',
        religion: '',
        id_num: '',
        //   nationalities
        nationalities: [],
        //   Address
        sameAddress: true,
        address: {
          city: '',
          country: 'egypt',
          district: '',
          street: ''
        },
        mobile: {
          key: '010',
          num: ''
        },
        tel: [
          {
            id: 0,
            key: '02',
            num: ''
          }
        ],
        sameOfficalEmail: true,
        official_email: '',

        school: '',
        university: '',
        occupation: '',
        employer: '',
        business_type: '',
        work: {
          address: '',
          email: '',
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          fax: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ]
        },
        school_alumni: 'school_alumni_true'
      },
      //mother
      mother: {
        name: {
          first: '',
          middle: '',
          last: ''
        },

        email: '',
        birth_date: '',
        religion: '',
        id_num: '',

        //   nationalities
        nationalities: [],

        //   Address
        sameAddress: true,
        address: {
          city: '',
          country: 'egypt',
          district: '',
          street: ''
        },
        mobile: {
          key: '010',
          num: ''
        },
        tel: [
          {
            id: 0,
            key: '02',
            num: ''
          }
        ],
        sameOfficalEmail: true,
        official_email: '',
        school: '',
        university: '',
        occupation: '',
        employer: '',
        business_type: '',
        work: {
          address: '',
          email: '',
          mobile: {
            key: '010',
            num: ''
          },
          tel: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ],
          fax: [
            {
              id: 0,
              key: '02',
              num: ''
            }
          ]
        },
        school_alumni: 'school_alumni_true'
      },
      // Siblings
      sibling_data: {
        have: 'siblings_have_false',
        siblings: [
          {
            id: 0,
            //   School data
            at_school: 'at_school_true',
            school_data: {
              have_id: 'have_id_true',
              id_num: ''
            }
          }
        ]
      },
      // Martial Data
      marital_data: {
        status: 'married_couple', //.. married_couple || divorced || widowed_father || widowed_mother
        custody: {
          who: 'others', //.. custody_widowed_father || custody_widowed_mother || custody_divorced_mother || custody_divorced_father || others
          others: {
            name: {
              first: '',
              middle: '',
              last: ''
            },

            email: '',
            sameOfficalEmail: true,
            birth_date: '',
            gender: 'male',
            religion: '',

            //   nationality
            nationality: '',

            //   Address
            sameAddress: true,
            address: {
              city: '',
              country: 'egypt',
              district: '',
              street: ''
            },
            mobile: {
              key: '010',
              num: ''
            },
            tel: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ],
            relationship: ''
          }
        }
      },
      //   general_data
      general_data: {
        emergencies: [
          {
            id: 1,
            full_name: {
              sign: 'Mr',
              name: ''
            },
            address: {
              city: '',
              country: 'egypt',
              district: '',
              street: ''
            },
            mobile: [
              {
                id: 0,
                key: '010',
                num: ''
              }
            ],
            tel: [
              {
                id: 0,
                key: '02',
                num: ''
              }
            ],
            relationship: ''
          }
        ],
        transpaortation: 'transpaortation_true',
        map: {
          address: '',
          position: {
            lat: 30.039374,
            lng: 31.23143
          }
        },
        health: {
          problem: 'problem_false',
          details: ''
        },
        submit_before: 'submit_before_false',
        files: {
          student_photo: {
            url: ''
          },
          birth_certificate: {
            url: ''
          },
          father_ID: {
            url: ''
          },
          mother_ID: {
            url: ''
          }
        }
      }
    }
  },
  methods: {
    updateDivisionGrade (v) {
      if (v == 'grade') {
        this.student.grade = ''
      } else {
        this.student.division = ''
        this.student.grade = ''
      }

    },
    // ==================================================== step 1 =================================
    validateStep1 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            // resolve(true)

            OnlineAdmissionService.step1(this.handleDataToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                if (res.data.status == 'updated') {
                  this.$vs.notify({
                    title: 'mail send before ',
                    text: 'Mail Has Been Send befor for this email ',
                    color: 'primary'
                  })
                } else if (res.data.status == 'create') {
                  this.$vs.notify({
                    title: 'Email Success ',
                    text: 'Mail Has Been Send ',
                    color: 'success'
                  })
                }
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
          } else {
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Enter Required Data !',
              color: 'danger'
            })
          }
        })
      })
    },

    handleSucessSaveResponse (res, is_first_step = false) {
      if (is_first_step) this.studentData_popupActive = true
      this.backeErrors = null
      this.admission_data_id = res.admission_id
    },
    handleDataToSend () {

      let nationality = new Set(),
        contact_telephone = new Set(),
        mobile_num = `${this.student.mobile.key}-${this.student.mobile.num}`
      for (let i = 0; i < this.student.nationalities.length; i++) {
        nationality.add(this.student.nationalities[i])
      }

      for (let i = 0; i < this.student.tel.length; i++) {
        contact_telephone.add(
          `${this.student.tel[i].key}-${this.student.tel[i].num}`
        )
      }

      return {
        admission_id: this.admission_data_id,
        first_name: this.student.name.first,
        middle_name: this.student.name.middle,
        last_name: this.student.name.last,
        birth_date: this.student.birth_date,
        gender: this.student.gender,
        religion: this.student.religion,
        nationality: Array.from(nationality),
        contact_mobile: mobile_num,
        contact_telephone: Array.from(contact_telephone),
        student_email: this.student.email,
        official_email: this.student.official_email,
        city: this.student.address.city,
        ditsrtict: this.student.address.district,
        street: this.student.address.street,
        country: this.student.address.country,
        school: this.student.school,
        division: this.student.division,
        school_year: this.student.school_year,
        year_group: this.student.grade,
        month_to_start: this.student.school_month_start,
        student_id: this.student.id_num
      }
    },
    //================================================= end ==========================================

    //================================================== Step 2 ======================================
    validateStep2 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-2').then(result => {
          if (result) {
            OnlineAdmissionService.step2(this.handleDataStep2ToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
            // resolve(true)
          } else {
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Please enter your data',
              color: 'danger'
            })
          }
        })
      })
    },
    handleDataStep2ToSend () {
      const schools = []
      for (let index = 0; index < this.student_info.schools.length; index++) {
        schools[index] = {
          name: this.student_info.schools[index].name,
          division: this.student_info.schools[index].division,
          year_group: this.student_info.schools[index].grade,
          school_year: this.student_info.schools[index].year,
          reason_leave: this.student_info.schools[index].leaving_reason
        }
      }

      return {
        admission_id: this.admission_data_id,
        first_join: this.student_info.first_join == 'first_join_true' ? 1 : 0,
        schools,
        step: 2,
        mother_tongue: this.student_info.languages.mother,
        common_speak: this.student_info.languages.common,
        first_foregin_langue: this.student_info.languages.first_foregin,
        arabic_exempted:
          this.student_info.child_exempted == 'child_exempted_true' ? 1 : 0,
        is_skipped_grade:
          this.student_info.child_skipped.do == 'child_skipped_false' ? 0 : 1,
        skipped_grade: this.student_info.child_skipped.grade,
        is_asked_repeat:
          this.student_info.child_repeat.do == 'child_repeat_false' ? 0 : 1,
        repeat_degree: this.student_info.child_repeat.grade,
        take_support_service:
          this.student_info.support_services.do == 'support_services_false'
            ? 0
            : 1,
        support_report: this.student_info.support_services.details,
        reson_for_applly: this.student_info.applying_reason
      }
    },

    //=====================================================================================================
    //======================================== step3 =====================================================
    validateStep3 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-3').then(result => {
          if (result) {
            OnlineAdmissionService.step3(this.handleDataStep3ToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
          } else {
            this.backeErrors = res.errors
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Please enter your data',
              color: 'danger'
            })
          }
        })
      })
    },
    handleDataStep3ToSend () {
      let nationality = new Set(),
        contact_telephone = new Set(),
        work_telephone = new Set(),
        work_fax = new Set(),
        mobile_num = `${this.father.mobile.key}-${this.father.mobile.num}`
      for (let i = 0; i < this.father.nationalities.length; i++) {
        nationality.add(this.father.nationalities[i])
      }

      for (let i = 0; i < this.father.tel.length; i++) {
        contact_telephone.add(
          `${this.father.tel[i].key}-${this.father.tel[i].num}`
        )
      }

      for (let i = 0; i < this.father.work.tel.length; i++) {
        work_telephone.add(
          `${this.father.work.tel[i].key}-${this.father.work.tel[i].num}`
        )
      }

      for (let i = 0; i < this.father.work.fax.length; i++) {
        work_fax.add(
          `${this.father.work.fax[i].key}-${this.father.work.fax[i].num}`
        )
      }

      return {
        admission_id: this.admission_data_id,
        step: 3,
        first_name: this.father.name.first,
        middle_name: this.father.name.middle,
        last_name: this.father.name.last,
        birth_date: this.father.birth_date,
        nationality: Array.from(nationality),
        is_same_child: this.father.sameAddress ? 1 : 0,
        city: this.father.address.city,
        country: this.father.address.country,
        ditsrtict: this.father.address.district,
        street: this.father.address.street,
        contact_mobile: mobile_num,
        contact_telephone: Array.from(contact_telephone),
        contact_email_official: this.father.sameOfficalEmail ? 1 : 0,
        contact_email: this.father.sameOfficalEmail
          ? this.student.official_email
          : this.father.email,
        education_school: this.father.school,
        education_univercity: this.father.university,
        occupation: this.father.occupation,
        is_school_alumni:
          this.father.school_alumni == 'school_alumni_true' ? 1 : 0,
        father_id: this.father.id_num,
        employer: this.father.employer,
        type_business: this.father.business_type,
        work_address: this.father.work.address,
        work_mobile: `${this.father.work.mobile.key}-${this.father.work.mobile.num}`,
        work_telephone: Array.from(work_telephone),
        work_fax: Array.from(work_fax),
        work_email: this.father.work.email,
        religion: this.father.religion
      }
    },
    //============================================== end========================================
    //============================================== step 4 =======================================
    validateStep4 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-4').then(result => {
          if (result) {
            OnlineAdmissionService.step4(this.handleDataStep4ToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
          } else {
            this.backeErrors = res.errors
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Please enter your data',
              color: 'danger'
            })
          }
        })
      })
    },
    handleDataStep4ToSend () {
      let nationality = new Set(),
        contact_telephone = new Set(),
        work_telephone = new Set(),
        work_fax = new Set(),
        mobile_num = `${this.mother.mobile.key}-${this.mother.mobile.num}`
      for (let i = 0; i < this.mother.nationalities.length; i++) {
        nationality.add(this.mother.nationalities[i])
      }

      for (let i = 0; i < this.mother.tel.length; i++) {
        contact_telephone.add(
          `${this.mother.tel[i].key}-${this.mother.tel[i].num}`
        )
      }

      for (let i = 0; i < this.mother.work.tel.length; i++) {
        work_telephone.add(
          `${this.mother.work.tel[i].key}-${this.mother.work.tel[i].num}`
        )
      }

      for (let i = 0; i < this.mother.work.fax.length; i++) {
        work_fax.add(
          `${this.mother.work.fax[i].key}-${this.mother.work.fax[i].num}`
        )
      }

      return {
        admission_id: this.admission_data_id,
        step: 4,
        first_name: this.mother.name.first,
        middle_name: this.mother.name.middle,
        last_name: this.mother.name.last,
        birth_date: this.mother.birth_date,
        nationality: Array.from(nationality),
        is_same_child: this.mother.sameAddress ? 1 : 0,
        city: this.mother.address.city,
        country: this.mother.address.country,
        ditsrtict: this.mother.address.district,
        street: this.mother.address.street,
        contact_mobile: mobile_num,
        contact_telephone: Array.from(contact_telephone),
        contact_email_official: this.mother.sameOfficalEmail ? 1 : 0,
        contact_email: this.mother.sameOfficalEmail
          ? this.student.official_email
          : this.father.email,
        education_school: this.mother.school,
        education_univercity: this.mother.university,
        occupation: this.mother.occupation,
        is_school_alumni:
          this.mother.school_alumni == 'school_alumni_true' ? 1 : 0,
        mother_id: this.mother.id_num,
        employer: this.mother.employer,
        type_business: this.father.business_type,
        work_address: this.mother.work.address,
        work_mobile: `${this.mother.work.mobile.key}-${this.mother.work.mobile.num}`,
        work_telephone: Array.from(work_telephone),
        work_fax: Array.from(work_fax),
        work_email: this.mother.work.email,
        religion: this.mother.religion
      }
    },
    // =============================================  end ==========================================
    // ================================================== step 5 ===============================
    validateStep5 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-5').then(result => {
          if (result) {
            OnlineAdmissionService.step5(this.handleDataStep5ToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
          } else {
            this.backeErrors = res.errors
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Please enter your data',
              color: 'danger'
            })
          }
        })
      })
    },
    handleDataStep5ToSend () {
      const siblings = []

      for (let i = 0; i < this.sibling_data.siblings.length; i++) {
        const sibling = this.sibling_data.siblings[i]
        const contact_telephone = new Set()
        let have_student_id = 1
        if (sibling.at_school == 'at_school_false') {
          have_student_id = null
        } else {
          have_student_id =
            sibling.school_data.have_id == 'have_id_true' ? 1 : 0
        }

        siblings[i] = {
          is_scholl_siblling: sibling.at_school == 'at_school_true' ? 1 : 0,
          have_student_id,
          id: have_student_id == 1 ? sibling.school_data.id_num : 0,
          idinfo: {
            division: have_student_id == 0 ? sibling.school_data.division : '',
            year_group: have_student_id == 0 ? sibling.school_data.grade : '',
            scholl_year:
              have_student_id == 0 ? sibling.school_data.school_year : ''
          }
        }
      }

      return {
        admission_id: this.admission_data_id,
        step: 5,
        have_sibling: this.sibling_data.have == 'siblings_have_true' ? 1 : 0,
        siblings:
          this.sibling_data.have == 'siblings_have_true' ? siblings : null
      }
    },
    // ============================================= end =============================================
    // ============================================= step 6 =========================================
    validateStep6 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-6').then(result => {
          if (result) {
            OnlineAdmissionService.step6(this.handleDataStep6ToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
          } else {
            this.backeErrors = res.errors
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Please enter your data',
              color: 'danger'
            })
          }
        })
      })
    },
    handleDataStep6ToSend () {
      let marital_status = 1
      if (this.marital_data.status == 'divorced') marital_status = 2
      if (this.marital_data.status == 'widowed_father') marital_status = 3
      if (this.marital_data.status == 'widowed_mother') marital_status = 4

      let apiData = {
        admission_id: this.admission_data_id,
        step: 6,
        marital_status
      }
      if (apiData.marital_status == 2) {
        const who = this.marital_data.custody.who
        if (who == 'custody_divorced_mother') {
          apiData['custody'] = 'mother'
        }
        if (who == 'custody_divorced_father') {
          apiData['custody'] = 'father'
        }
        if (who == 'others') {
          apiData['custody'] = 'other'
          apiData = {
            ...apiData,
            ...this.handlePersonObject(this.marital_data.custody.others)
          }
        }
      }
      if (apiData.marital_status == 3 || apiData.marital_status == 4) {
        const who = this.marital_data.custody.who
        if (
          who == 'custody_widowed_mother' ||
          who == 'custody_widowed_father'
        ) {
          apiData['custody'] = 1
        }

        if (who == 'others') {
          apiData['custody'] = 0
          apiData = {
            ...apiData,
            ...this.handlePersonObject(this.marital_data.custody.others)
          }
        }
      }
      return apiData
    },
    handlePersonObject (obj) {
      let nationality = new Set(),
        contact_telephone = new Set(),
        mobile_num = obj.mobile.num
          ? `${obj.mobile.key}-${obj.mobile.num}`
          : ''
      // for (let i = 0; i < obj.nationalities.length; i++) {
      //   nationality.add(obj.nationalities[i].value)
      // }

      for (let i = 0; i < this.mother.tel.length; i++) {
        if (obj.tel[i].num) contact_telephone.add(`${obj.tel[i].key}-${obj.tel[i].num}`)
      }
      return {
        first_name: obj.name.first,
        middle_name: obj.name.middle,
        last_name: obj.name.last,
        birth_date: obj.birth_date,
        religion: obj.religion,
        is_same_child: obj.sameAddress ? 1 : 0,
        city: !obj.sameAddress ? sibling.address.city : '',
        country: !obj.sameAddress ? sibling.address.country : '',
        ditsrtict: !obj.sameAddress ? sibling.address.district : '',
        street: !obj.sameAddress ? sibling.address.street : '',
        contact_mobile: mobile_num,
        contact_email_official: obj.sameOfficalEmail ? 1 : 0,
        contact_email: obj.sameOfficalEmail ? this.student.official_email : '',
        contact_telephone: Array.from(contact_telephone),
        nationality: obj.nationality,
        relationship: obj.relationship
      }
    },
    // ============================================= end   ===========================================
    // ============================================= step 7==========================================
    validateStep7 () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-7').then(result => {
          if (result) {
            OnlineAdmissionService.step7(this.handleDataStep7ToSend())
              .then(res => {
                this.handleSucessSaveResponse(res.data)
                resolve(true)
              })
              .catch(res => {
                reject('error validation')

                this.backeErrors = res.errors
                this.$vs.notify({
                  title: 'Error',
                  text: 'Please enter your data',
                  color: 'danger'
                })
              })
          } else {
            this.backeErrors = res.errors
            reject('correct all values')
            this.$vs.notify({
              title: 'Error',
              text: 'Please enter your data',
              color: 'danger'
            })
          }
        })
      })
    },
    handleDataStep7ToSend () {
      const emergency_contact = new Set()

      for (let i = 0; i < this.general_data.emergencies.length; i++) {
        const emergcy = this.general_data.emergencies[i]
        const mobiles = new Set()
        const tels = new Set()
        for (let j = 0; j < emergcy.mobile.length; j++) {
          const mobile = emergcy.mobile[j]
          if (mobile.num) {
            mobiles.add(`${mobile.key}-${mobile.num}`)
          }
        }
        for (let k = 0; k < emergcy.tel.length; k++) {
          const mobile = emergcy.tel[k]
          if (mobile.num) {
            tels.add(`${mobile.key}-${mobile.num}`)
          }
        }
        emergency_contact.add({
          full_name: emergcy.full_name.name,
          label: emergcy.full_name.sign,
          relation: emergcy.relationship,
          telephone: Array.from(tels),
          mobile: Array.from(mobiles),
          city: emergcy.address.city,
          country: emergcy.address.country,
          ditstrict: emergcy.address.district,
          streat: emergcy.address.street
        })
      }


      return {
        admission_id: this.admission_data_id,
        step: 7,
        emergency_contact: Array.from(emergency_contact),
        bus_transportation: 1,
        location: {
          lat: this.general_data.map.position.lat,
          lng: this.general_data.map.position.lng,
          address: this.general_data.map.address
        },
        permanent_healthy:
          this.general_data.health.problem == 'problem_true' ? 1 : 0,
        healthy_details:
          this.general_data.health.problem == 'problem_true'
            ? this.general_data.health.details
            : '',
        previously_submit:
          this.general_data.submit_before == 'submit_before_false' ? 0 : 1,
        student_image: this.general_data.files.student_photo.url,
        child_ceritificate_image: this.general_data.files.birth_certificate.url,
        father_id_image: this.general_data.files.father_ID.url,
        mother_id_image: this.general_data.files.mother_ID.url
      }
    },
    //===================================================== end =====================================================
    formSubmitted () {
      this.formSubmit_popupActive = true
      setTimeout(() => {
        this.formSubmit_popupActive = false
      }, 1500)
      setTimeout(() => {
        this.$router.push('/apply/success')
      }, 2000)
    },
    // Transition
    fullmoodClass (status) {
      if (status === 'leave') {
        setTimeout(() => {
          this.wfullClass = this.wfullClass.replace('lg:w-2/3', '')
        }, 500)

      } else {
        this.wfullClass += 'lg:w-2/3'
      }
    }
  },
  components: {
    ValidationErrors,
    FormWizard,
    TabContent,

    RegisterStudentData,
    RegisterStudentInfo,
    RegisterFatherData,
    RegisterMotherData,
    RegisterSiblingsData,
    RegisterMaritalData,
    RegisterGeneralData
  },
  provide () {
    return {
      $validator: this.$validator
    }
  },
  computed: {

  }
}
</script>
<style lang="scss">
.transition {
  transition: all 0.5s ease;
}

.register-tabs-container {
  max-height: 700px;
  overflow-y: auto;

  .con-tab {
    padding-bottom: 23px;
  }

  select,
  label,
  button span,
  .vs-select--options span {
    text-transform: capitalize;
  }
}

.vue-form-wizard.md .wizard-navigation .wizard-nav .wizard-icon-circle {
  width: 45px;
  height: 45px;

  .wizard-icon {
    font-size: 1.2rem;
  }
}

span.text-danger {
  text-transform: capitalize;
  font-size: 0.875rem !important;

  &.w-full {
    display: block;
  }
}

.fullscreen {
  position: absolute;
  right: 15px;
  top: 10px;

  .vs-button-primary.vs-button-filled {
    background-color: #eff2f7 !important;
    color: #2c2c32;
  }
}
</style>
